.pill {
  display: inline-block;

  button[type="button"],div[role="button"] {
    height: auto;
    padding: 2px;
    margin: 0;
    font-size: 14px;
    border-radius: 50%;
    text-align: center;
    //background-color: transparent;

    //&:focus {
    //  background-color: transparent;
    //}
  }

  &.tight {
    border-radius: 4px;
  }

  &.pill-large {
    font-size: 18px;
    line-height: 20px;
    padding: 8px 16px;
    border-radius: 24px;

    button,div[role="button"] {
      font-size: 26px;
    }
  }

  &.pill-small {
    font-size: 14px;
    line-height: 12px;
    padding: 4px 8px;
    border-radius: 16px;

    button,div[role="button"] {
      font-size: 22px;
    }
  }

  &.pill-deep-green {
    background-color: $deep-green;
    color: $white;
  }
}
