.styled-select.styled-select.styled-select.styled-select.styled-select.styled-select.styled-select.styled-select.styled-select.styled-select {
  all: unset;
  background-color: $dark-white;
  background-image: url("data:image/svg+xml;utf8,<svg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 512 512' height='16' width='16' xmlns='http://www.w3.org/2000/svg' style='color: rgb(1, 58, 129);'><path d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'></path></svg>");
  background-position-x: calc(100% - 10px);
  background-position-y: 17px;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-sizing: border-box;
  color: $smoke;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  padding: 17px 36px 13px 10px;
  position: relative;

  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
}
