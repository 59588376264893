@mixin on-mobile {
  @media screen and (max-width: 500px) {
    @content;
  }
}

.app-wrapper {
  background-color: $bg-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  main,
  > div {
    flex: 1;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0;
    width: 90%;
    &.tall {
      margin-top: 175px;
    }

    &.tall-pagination {
      margin-bottom: 120px;
    }

    &.tall-with-job-title {
      margin-top: 191px;
    }

    &.short {
      margin-top: 85px;
    }

    > .container {
      padding: 0;
    }
  }

  // This is only used for pages like TalentNetwork Signup that have a background image that has to go full width.
  &.full-width {
    width: 100%;

    > div {
      flex: auto;
      margin: 0 auto;
      width: 90%;
    }
  }

  .container {
    max-width: 100%;
    width: 100%;
  }

  .clickable {
    cursor: pointer;
  }

  .footer {
    margin: 32px auto 0 auto;
    max-width: 100%;
  }

  .note {
    color: $boulder;
    font-family: 'Karla', sans-serif;
    font-size: theme('fontSize.14');
  }

  table {
    font-size: theme('fontSize.14');
    width: 100%;
  }

  .table-headers {
    font-family: 'Karla', sans-serif;
    font-size: theme('fontSize.14');
    div {
      box-sizing: border-box;
      float: left;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .explanation::before,
  .explanation-small::before,
  .explanation-colored::before {
    color: $alabaster;
    content: '?';
    display: block;
    font-family: 'Karla', sans-serif;
    font-size: theme('fontSize.22');
    font-weight: 600;
    line-height: 20px;
    margin-left: 5px;
  }

  .explanation,
  .explanation-small,
  .explanation-colored {
    border: 2px solid $alabaster;
    border-radius: 100px;
    cursor: help;
    display: inline-block;
    height: 20px;
    margin-left: 10px;
    width: 20px;

    .tooltip {
      background: $tundora;
      display: inline-block;
      font-size: theme('fontSize.14');
      left: -145px;
      margin: 0;
      position: relative;
      &:before {
      }

      &:after {
        border-bottom-color: $tundora;
        left: 160px;
        margin-left: -13px;
      }
    }

    &:hover .tooltip {
      @include tooltip-hover();
    }
  }

  .explanation-small::before {
    color: $tundora;
    font-size: theme('fontSize.14');
    line-height: 16px;
    margin-left: 5px;
  }

  .explanation-small {
    border: 1px solid $tundora;
    height: 16px;
    margin-bottom: 2px;
    margin-left: 6px;
    vertical-align: bottom;
    width: 16px;
  }

  .explanation-colored::before {
    color: $deep-cerulean;
  }

  .explanation-colored {
    border: 2px solid $deep-cerulean;
  }
}

.dashboard-date {
  background: $white url('https://images.onaliro.com/client/icons/calendar.png') no-repeat scroll;
  background-position: right 10px center;
  background-size: 16px;
  padding: 0 6px 0 10px;

  text-align: left;

  cursor: pointer;
}

div.modal input[type='text'].dashboard-date {
  text-align: left;
}
