@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Karla:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Trocchi');

@import 'skeleton/normalize';
@import 'skeleton/skeleton';

@import 'spacing';
@import 'border';
@import 'dashboard';
@import 'icons';
@import 'animations';
@import 'util';
@import 'components/accordion';
@import '../lib/components/core/add-remove-pill/add-remove-pill';
@import 'components/avatar';
@import 'components/box';
@import 'components/buttons';
@import 'components/datagrid';
@import 'components/dropdown-menu';
@import 'components/tabs';
@import 'components/grid';
@import 'components/dropzone-upload';
@import 'components/header';
@import 'components/footer';
@import 'components/stepper';
@import 'components/preferences';
@import 'components/switch';
@import 'components/subnav';
@import 'components/pill';
@import 'components/text';
@import 'components/tooltip';
@import 'forms';
@import 'pagination';
@import 'tooltips';
@import 'menu';
@import 'modal';
@import 'datepicker';
@import 'consent';
@import 'app';
@import 'components/media-management';
@import 'components/email-templates';
@import 'components/search-sort-filter';
@import 'components/pipeline';
@import 'components/styled-button';
@import 'components/styled-color-input';
@import 'components/styled-input';
@import 'components/styled-label';
@import 'components/styled-radio';
@import 'components/styled-select';
@import 'components/styled-text-area';
@import './properties.scss';

html {
  font-size: 62.5%;
  height: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  background: $alabaster;
  color: theme('colors.offblack');
  font-family: theme('fontFamily.body');
  font-weight: 400;
}

span.back {
  cursor: pointer;
}

.group:after {
  clear: both;
  content: '';
  display: table;
}

h1,
h2 {
  letter-spacing: normal;
  margin: 0;
}

textarea {
  resize: none;
}

input[type='email'] {
  text-transform: lowercase;
}

// Underlined header
.h-underlined {
  border-bottom: 1px solid $alto;
  padding-bottom: 0;
}

.style-box {
  height: 100px;
  width: 100%;

  p {
    font-size: 2em;
    margin: 0 auto;
  }
}

.warning {
  color: $valencia;
}

.add-link {
  background: url('https://images.onaliro.com/client/icon_plus.svg') no-repeat scroll left center / 12px;
  padding-left: 22px;
}

.or-divider {
  display: flex;
  margin: 10px 0 0;

  span {
    color: $emperor;
    flex: 1 1 auto;
    font-size: theme('fontSize.14');
    line-height: 2.75;
    text-align: center;
  }

  &::before,
  &::after {
    border-top: 1px solid $alto;
    content: '';
    flex: 1 1 auto;
    margin: 19px 0;
    width: 30%;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0);
  bottom: 100%;
  height: 100%;
  left: 0;
  position: fixed;
  right: 100%;
  top: 0;
  transition:
    background 0.25s,
    z-index 0.25s;
  width: 100%;
  z-index: -100;

  &.active {
    background: rgba(0, 0, 0, 0.65);
    transition: background 0.25s;
    z-index: 100;

    .modal {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  &.loading-overlay.active {
    background: rgba(0, 0, 0, 0);
  }
}

body.scroll-lock {
  overflow: hidden;
}

.fifty-tm {
  margin-top: 50px;
}

.thirty-tm {
  margin-top: 30px;
}

.twenty-tm {
  margin-top: 20px;
}

.fourteen-tm {
  margin-top: 14px;
}

.twelve-tm {
  margin-top: 12px;
}

.ten-tm {
  margin-top: 10px;
}

.neg-five-tm {
  margin-top: -5px;
}

.mobile-hidden {
  display: inline-block;
}

.initial-loading {
  opacity: 0;
}

.normal-header {
  margin-top: 91px;
  padding-top: 34px;
}

.required {
  color: $valencia;
  padding-right: 4px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
