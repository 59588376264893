.dashboard-block {
  .dashboard-block-loading {
    margin: 0 auto;
    padding: 20px 0;
  }

  .dashboard-no-data {
    font-size: theme('fontSize.16');
  }

  .dashboard-error-loading {
    img {
      filter: alpha(opacity=10); /* For IE8 and earlier */
      height: 180px;
      opacity: 0.1;
      width: auto;
    }
    text-align: center;
  }

  .dashboard-header {
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    align-items: center;
    background-color: $wild-sand;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    padding: 10px 14px;

    h3 {
      color: $deep-cerulean-lighter;
    }

    .selector-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;

      .selector-label {
        color: $deep-cerulean-lighter;
        margin-right: 5px;
        margin-bottom: 0px;
        font-family: 'Roboto Slab', serif;
        font-weight: 400;
      }

      .selector-select {
        margin: 0 0 0 20px;
        height: auto;
        background-color: transparent;
        border: 1;
        border: 2px $deep-cerulean-lighter solid;
        color: $deep-cerulean-lighter;
        padding-left: 10px;
      }
    }
  }

  .dashboard-content {
    -moz-border-radius-bottomleft: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    background-color: $wild-sand;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: theme('fontSize.14');
    padding: 27px 28px;

    .dashboard-descriptor {
      display: block;
      margin-bottom: 15px;
    }

    .item {
      margin-bottom: 20px;
      .primary {
        color: $emperor;
        font-size: theme('fontSize.14');
      }

      .secondary {
        color: $dusty-gray;
        font-size: theme('fontSize.14');
      }
    }
  }
}
