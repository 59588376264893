.email-template-manager {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .email-templates {
    .email-template {
      @extend .u-p-2;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      background: $wild-sand;
      border: 1px $alto solid;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.2s;

      h5 {
        @extend .u-m-0;
      }
    }

    .actions {
      .button {
        margin-top: 0;
      }
    }
  }

  .tabs {
    .links {
      @extend .u-mb-1;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .sections {
      @extend .u-m-0;
    }
  }
}

.tox-hugerte-aux {
  pointer-events: auto;
}

.tox-hugerte-aux {
  position: relative !important;
  z-index: 10055;
}
