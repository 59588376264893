.datagrid-container {
  width: 100%;
  font-size: theme('fontSize.16');
  color: $off-black;

  .datagrid-head {
    @extend .u-mb-2;
    flex: 1;
    display: flex;
    flex-direction: row;
    background: $tealish-blue;
    margin-bottom: 1rem;

    form {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin: 0;
    }

    .datagrid-head-left {
      flex: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0;
      @extend .u-m-0;
      @extend .u-p-0;

      .datagrid-search {
        @extend .u-m-0;

        input {
          @extend .u-m-0;
        }
      }
    }

    .datagrid-head-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      justify-self: flex-end;

      .datagrid-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .button {
          @extend .u-mt-0;
          @extend .u-mb-0;
          @extend .u-mr-2;

          &:last-child {
            @extend .u-mr-0;
          }
        }
      }

      .datagrid-filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;

        button {
          margin: 0;
        }
      }
    }

    .form-input-container {
      margin: 0 0 0 2rem;

      &.datagrid-search {
        margin: 0;
      }

      padding: 0;

      label {
        font-size: theme('fontSize.14');
        margin: 0;
        padding: 0;
        color: $off-black;
      }

      input,
      select {
        min-width: 120px;
        background-color: white;
        margin-bottom: 0;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: transparent;
    font-size: 1.5rem;

    td,
    th {
      @extend .u-p-1;
      line-height: unset;
      padding: 10px;

      p,
      span:not(.icon),
      a,
      button {
        margin: 0;
      }
    }

    thead {
      border: none;
      background: transparent;

      tr {
        th {
          @extend .u-pr-4;
          background-color: $dusky-blue;
          color: $white;
          vertical-align: middle;
          border-bottom-width: 0;

          &.sortable {
            cursor: pointer;

            span.icon {
              margin-top: 2px;
              display: none;
              @include for-size(tablet-portrait-up) {
                display: inline-block;
              }
            }
          }
        }
      }

      tr.black-text th {
        color: $off-black;
      }

      tr:first-child th:first-child {
        border-top-left-radius: 4px;
      }

      tr:first-child th:last-child {
        border-top-right-radius: 4px;
      }

      tr:last-child th:first-child {
        border-bottom-left-radius: 4px;
      }

      tr:last-child th:last-child {
        border-bottom-right-radius: 4px;
      }

      &::after {
        content: '';
        display: block;
        height: 10px;
        clear: both;
      }
    }

    tbody {
      border: none;
      background: $white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      padding: 2rem;

      tr {
        border: none;
        padding: 0 5px;
        vertical-align: middle;

        td {
          padding: 15px 5px;
          vertical-align: middle;

          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }

        &:first-child {
          td {
            &:first-child {
              border-top-left-radius: 4px;
            }

            &:last-child {
              border-top-right-radius: 4px;
            }
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 4px;
            }

            &:last-child {
              border-bottom-right-radius: 4px;
            }
          }
        }

        &.expanded-row:not(.expanded-row--expanded) {
          td {
            padding: 0;
            border: 0;
          }
        }

        &.expanded-row {
          display: none;
          div {
            -webkit-transition: max-height 0.4s cubic-bezier(0, 1.05, 0, 1);
            -moz-transition: max-height 0.4s cubic-bezier(0, 1.05, 0, 1);
            -ms-transition: max-height 0.4s cubic-bezier(0, 1.05, 0, 1);
            -o-transition: max-height 0.4s cubic-bezier(0, 1.05, 0, 1);
            transition: max-height 0.4s cubic-bezier(0, 1.05, 0, 1);
          }

          &.expanded-row--expanded {
            display: table-row;
            div {
              -webkit-transition: max-height 0.5s ease;
              -moz-transition: max-height 0.5s ease;
              transition: max-height 0.5s ease;
            }
          }
        }
      }

      button {
        margin: 0;
      }
    }
  }

  input.form-input.datagrid-select-box {
    //margin: 0;
    //padding: 0;
  }

  .pagination {
    .page-arrow {
      cursor: pointer;

      .icon {
        svg {
          height: 70%;
          width: 70%;
          margin: 15%;
        }
      }
    }
  }

  thead tr:nth-child(2) {
    th {
      background-color: white;
      border-style: solid;
      border-color: $dusky-blue;
      border-width: 0;
      border-bottom-width: 1px;
      padding: 0.2rem;
    }

    th:first-child {
      border-left-width: 1px;
    }

    th:last-child {
      border-right-width: 1px;
    }
  }

  &.datagrid-no-margin {
    table {
      margin: 0;
    }
  }

  &.datagrid-minimal {
    table {
      border: none;
      margin: 0;

      tbody {
        border: none;
        box-shadow: none;
        padding: 0;

        tr {
          td,
          th {
            padding: 0.25rem 0;
            border: none;
          }
        }
      }
    }
  }

  &.datagrid-inline-head {
    .datagrid-head {
      flex-direction: row;
      margin: 1rem;

      .datagrid-head-left {
        flex-direction: row;
      }

      .datagrid-head-right {
        flex-direction: row-reverse;
        align-items: baseline;
        justify-content: space-between;
      }
    }
  }
}
