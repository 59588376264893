@property --alert-color {
  syntax: '<color>';
  inherits: true;
  initial-value: theme('colors.teal.400');
}
@property --alert-bg {
  syntax: '<color>';
  inherits: true;
  initial-value: theme('colors.teal.100');
}

.alert-error,
.toast-error {
  --alert-color: theme('colors.pink.DEFAULT');
  --alert-bg: theme('colors.pink.200');
}

.alert-success,
.toast-success {
  --alert-color: theme('colors.green.500');
  --alert-bg: theme('colors.green.200');
}
