.dropzone-upload {
  @extend .container;
  @extend .u-p-0;
  @extend .u-border-radius-1;
  border: none;
  outline: none;
  transition: border 0.24s ease-in-out;

  .dropzone-content {
    @extend .u-p-4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: lighten($emerald, 50%);
    cursor: pointer;
    border: 0;
    color: $black;

    &.has-document {
      background: $white;
      flex-direction: row;
      justify-content: flex-start;
      border: 0;
      border-bottom: 1px solid $alto;
      border-radius: 0;
    }

    &:hover {
      background: lighten($emerald, 40%);
      border: 1px dashed $emerald;
    }

    .icon {
      @extend .u-ml-2;
      @extend .u-mr-2;
      color: $emerald;
      font-size: theme('fontSize.40');
    }

    p {
      margin: 0;
    }

    .file-name {
      flex: 1;
    }

    button.button-icon {
      @extend .u-m-0;
      @extend .u-p-0;
      background-color: transparent;

      .icon {
        font-size: 0.5rem;
        color: $black;
      }
    }

    p {
      color: $black;
    }
  }

  &.is-active {
    border: 2px dashed $deep-cerulean-lighter;
  }

  &.is-accepted {
    border: 2px dashed $emerald;
  }

  &.is-rejected {
    border: 2px dashed $valencia;
  }

  &.is-focused {
    border: 2px dashed $deep-cerulean;
  }

  .overlay {
    @extend .u-p-1;
    @extend .u-border-radius-1;
    transition: all 0.25s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;

    &.is-active {
      display: flex;
      background-color: lighten($deep-cerulean-lighter, 40%);
      z-index: 1;
      opacity: 1;
    }

    &.is-accepted {
      background-color: lighten($emerald, 40%);
    }

    &.is-rejected {
      background-color: lighten($valencia, 40%);
    }

    &.is-focused {
      background-color: lighten($deep-cerulean, 40%);
    }
  }

  border-radius: 10px;

  &.is-disabled {
    border: 0;
    background-color: transparent;

    &:hover,
    .dropzone-content:hover {
      border: 0;
      background-color: transparent;
      cursor: default;
    }

    .dropzone-content.has-document {
      //border-bottom: 1px solid $alto;
    }
  }
}
