.accordion-root {
  .accordion-item {
    border: none;
    margin-bottom: 1rem;

    h3.accordion-header {
      font-family: 'Roboto', sans-serif;
      margin-bottom: 0;

      button.accordion-trigger {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        background: transparent;
        color: $off-black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        &:hover,&:focus {
          color: $off-black!important;
        }
      }
    }

    div.accordion-content {
      p.accordion-contenttext {
        margin-bottom: 1rem;
      }
    }
  }
}
