.u-hidden {
  display: none;
}

.u-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.u-hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.italic {
  font-style: italic;
}
