@mixin on-mobile {
  @media screen and (max-width: 500px) {
    @content;
  }
}

div.stepper-wrapper {
  @extend .u-pt-0;
  @extend .u-pb-0;

  * {
    box-sizing: border-box;
  }

  &.vertical-stepper-wrapper {
    @extend .u-pt-0;
    @extend .u-pb-0;
    @include on-mobile {
      height: 100%;
    }

    .stepper-head {
      flex: 1;

      .stepper-label {
        font-size: 1.75rem;
        flex: 1;
      }
    }

    .stepper-body {
      @include on-mobile {
        z-index: 100;
        background-color: $alabaster;
        position: absolute;
        width: 100vw;
        height: 100vh;
        left: -100vw;
        top: 0;
        bottom: 0;
        overflow: scroll;

        -webkit-margin-start: 0px;
        margin-inline-start: 0px;

        -webkit-transition: left 0.15s linear;
        -moz-transition: left 0.15s linear;
        -ms-transition: left 0.15s linear;
        -o-transition: left 0.15s linear;
        transition: left 0.15s linear;

        &.open {
          left: 0;

          -webkit-transition: left 0.15s linear;
          -moz-transition: left 0.15s linear;
          -ms-transition: left 0.15s linear;
          -o-transition: left 0.15s linear;
          transition: left 0.15s linear;
        }
      }

      margin-inline-start: 3.5rem;

      .stepper-body-head {
        display: none;
        @extend .u-mt-4;
        @extend .u-mb-4;
        @extend .u-pt-4;
        @extend .u-pb-4;
        flex-direction: row;
        align-items: center;

        @include on-mobile {
          display: flex;

          button.close {
            position: absolute;
            right: 10px;
            top: 10px;
            margin: 0;
            padding: 0;
            border: none;
            background: none;
            font-size: 1.5rem;
            color: $black;
            cursor: pointer;
          }
        }

        h2 {
          text-align: center;
        }
      }
    }
  }

  /* styles of default stepper */
  .stepper-head {
    display: flex;
    position: relative;
    width: 100%;
    user-select: none;

    .stepper-arrow {
      display: none;
      @extend .u-ml-2;

      &.complete {
        display: block;
        color: $emerald;
      }
    }

    /* styles of inline stepper */
    &.inline-stepper-head {
      .stepper-step {
        &:after {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .stepper-indicator,
      .stepper-label {
        display: inline-block;
        vertical-align: middle;
      }

      .stepper-label {
        font-size: theme('fontSize.14');
        text-align: left;
        padding: 0 0.5rem;
        background: transparent;
      }
    }

    /* styles of vertical stepper */
    &.vertical-stepper-head {
      @extend .u-pr-4;
      border: 0;
      border-right: 1px solid $wild-sand;
      flex-direction: column;
      flex: 1;

      @include on-mobile {
        border: 0;
      }

      .stepper-step {
        display: flex;
        align-items: center;
        text-align: start;
      }

      .stepper-label {
        padding-inline-start: 1rem;
      }

      @include on-mobile {
        width: auto;
        height: 100%;

        .stepper-arrow {
          display: block;
        }
      }
    }

    .stepper {
      &-step {
        position: relative;
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 3rem;

        &.is-clickable {
          cursor: pointer;
        }

        &.is-complete {
          .stepper-indicator-info {
            border-color: $emerald;
            background-color: $emerald;
            cursor: pointer;
          }

          .stepper-label {
            color: darken($deep-cerulean, 20%);
          }
        }

        &.is-active {
          .stepper-indicator-info {
            border-color: $deep-cerulean;
            background-color: $deep-cerulean;
            cursor: initial;
          }

          .stepper-label {
            color: $deep-cerulean;
          }
        }

        //&:after {
        //  content: " ";
        //  position: absolute;
        //  left: 50%;
        //  top: 1.5rem;
        //  width: 100%;
        //  height: 0.125rem;
        //  background-color: $alto;
        //  z-index: 1;
        //}
        //
        //&:last-child:after {
        //  display: none;
        //}
      }

      &-indicator {
        position: relative;
        display: block;
        z-index: 2;

        &-info {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          border: 1px solid $alto;
          border-radius: 50%;
          background-color: $alto;
          font-size: 1rem;
          line-height: 2.5rem;
          text-align: center;
          color: $alabaster;
          z-index: 2;

          .stepper-tick {
            width: 14px;
            fill: $alabaster;
          }
        }
      }

      &-label {
        position: relative;
        display: block;
        margin: 0.5rem 0;
        color: $silver;
        z-index: 2;
      }
    }
  }

  .stepper-body {
    flex: 1;
    min-height: 100px;
    padding: 10px 0;

    &.vertical-stepper-body {
      flex: 2.5;
    }
  }

  .stepper-footer {
    display: flex;
    align-items: center;
    padding-top: 10px;

    &-btn {
      color: rgba(0, 0, 0, 0.87);
      padding: 6px 16px;
      font-size: 0.875rem;
      min-width: 64px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 500;
      line-height: 1.75;
      border-radius: 4px;
      letter-spacing: 0.02857em;
      cursor: pointer;
      border: none;
      outline: none;

      &.primary {
        color: $alabaster;
        background-color: $deep-cerulean;
      }

      &.success {
        color: $alabaster;
        background-color: $emerald;
      }

      &:disabled {
        color: rgba(0, 0, 0, 0.26);
        background-color: rgba(0, 0, 0, 0.12);
        cursor: not-allowed;
      }
    }
  }
}

.step-number {
  @extend .u-p-0;
  @extend .u-m-0;
  font-size: theme('fontSize.18');
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;

  background: $wild-sand;
  color: $boulder;
  border: $boulder 2px solid;

  svg {
    vertical-align: middle;
  }

  &.active {
    background: $wild-sand;
    color: $boulder;
    border: $emerald 2px solid;
  }

  &.complete,
  &.active.complete {
    background: $emerald;
    color: $white;
    border: $emerald 2px solid;
  }
}
