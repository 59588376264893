.add-remove-pill {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-size: theme('fontSize.16');

  .add-remove-pill__content {
    background-color: $very-light-grey;
    border: solid 1px $very-light-grey;
    border-radius: 0.5em;
    box-sizing: border-box;
    color: $dark-text;
    cursor: pointer;
    display: flex;
    gap: 0.857em;
    justify-content: space-between;
    line-height: 1.25em;
    padding: 0.5em 1em;
    width: max-content;

    &:focus {
      background-color: darken($very-light-grey, 10%);
      border-color: darken($very-light-grey, 10%);
    }

    svg {
      align-self: center;
      fill: $smoke;
    }
  }

  &.add-remove-pill--active {
    .add-remove-pill__content {
      background-color: $bg-color;
      border-color: $deep-cerulean;

      &:focus {
        background-color: darken($bg-color, 10%);
        border-color: darken($deep-cerulean, 10%);
      }
    }
  }
}
