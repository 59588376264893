.datepicker-container {
  position: relative;
  margin-bottom: 19px;

  .rmdp-container {
    input[type='text'] {
      margin-bottom: 0px;
    }
  }

  .rmdp-wrapper {
    @extend .u-border-radius-2;

    input[type='text'] {
      height: auto;
      margin-bottom: auto;
      font-size: theme('fontSize.14');
    }

    .rmdp-week-day {
      color: $deep-cerulean;
    }

    .rmdp-day {
      &.rmdp-today span {
        background-color: $deep-cerulean-lighter;
      }

      &.rmdp-selected span:not(.highlight) {
        background-color: $deep-cerulean;
        box-shadow: 0 0 3px $dark-grayish-blue;
        color: $white;
      }

      &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        background-color: $deep-cerulean;
        color: $white;
      }
    }

    .rmdp-arrow-container {
      .rmdp-arrow {
        border-color: $deep-cerulean;
      }

      &:hover {
        background-color: $deep-cerulean;

        .rmdp-arrow {
          border-color: $white;
        }
      }
    }
  }

  button.button {
    margin: 0;
    left: -50px;
    top: 2px;
    line-height: normal;

    .icon {
      line-height: normal;
      svg {
        width: 45%;
        height: 45%;
        margin: 27.5%;
      }
    }
  }
}
