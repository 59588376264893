.button,
button:not(.switch-root):not([role='checkbox']):not(.unstyled-button):not(.tox-tbtn):not(.ReactTags__remove):not(
    [class^='Toastify']
  ) {
  line-height: 2.25;
  position: relative;

  .progress {
    left: 8px;
    position: absolute;
    top: 6px;
  }
}

input[type='submit'],
input[type='reset'],
input[type='button'] {
  line-height: 1;
}

.button,
button:not(.switch-root):not([role='checkbox']):not(.unstyled-button):not(.tox-tbtn):not(.ReactTags__remove):not(
    [class^='Toastify']
  ),
input[type='submit'],
input[type='reset'],
input[type='button'] {
  background-color: $dusty-purple;
  border: 0;
  border-radius: 4px;
  color: $white;
  font-family: 'Inter', serif;
  font-size: theme('fontSize.16');
  font-weight: $fw-medium;
  letter-spacing: normal;
  text-transform: none;
  vertical-align: center;
  line-height: 2.25;
  padding: 2px 25px;
  height: 40px;

  &:not(.u-full-width) {
    width: auto;
  }

  &.button-small {
    font-size: 1.25rem;
    font-weight: $fw-regular;
    line-height: 2;
    padding: 0 22px;
    height: 34px;
  }

  &.button-large {
    font-size: theme('fontSize.18');
    font-weight: $fw-medium;
    line-height: 2.25;
    padding: 4px 30px;
    height: 48px;
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }

  &.button-home {
    font-size: theme('fontSize.16');
  }

  &.button-primary {
    background-color: $deep-cerulean;

    &:hover.disabled,
    &:hover[disabled] {
      background-color: $deep-cerulean;
    }

    &:hover:not(.disabled):not([disabled]),
    &:focus:not(.disabled):not([disabled]) {
      background: darken($deep-cerulean, 10%);
    }
  }

  &.button-ghost {
    background: transparent;
    border: 1px solid $deep-cerulean;
    color: $deep-cerulean;
    font-size: 1.3rem;
    padding: 0.5rem 1.4rem;

    &:focus:not(.disabled):not([disabled]),
    &:hover:not(.disabled):not([disabled]),
    &:active:not(.disabled):not([disabled]) {
      background: $deep-cerulean;
      color: $white;
    }
  }

  &.button-secondary {
    background-color: $hawkes-blue;
    color: $deep-cerulean;

    &:hover.disabled,
    &:hover[disabled] {
      background-color: $hawkes-blue;
    }

    &:hover:not(.disabled):not([disabled]),
    &:focus:not(.disabled):not([disabled]) {
      background: darken($hawkes-blue, 10%);
    }
  }

  &.button-white {
    background: $white;
    color: $regent-gray;

    &:hover:not(.disabled):not([disabled]) {
      background: transparent;
      color: $white;
    }
  }

  &.button-blue {
    background: $deep-cerulean;

    &:hover:not(.disabled):not([disabled]) {
      background: darken($deep-cerulean, 10%);
    }
  }

  &.button-purple {
    background-color: $purple;

    &:hover:not(.disabled):not([disabled]) {
      background-color: darken($purple, 10%);
    }
  }

  &.button-cancel {
    background-color: $regent-gray;

    &:hover:not(.disabled):not([disabled]) {
      background: darken($regent-gray, 10%);
      color: $white;
    }
  }

  &.button-draft {
    background-color: $regent-gray;

    &:hover:not(.disabled):not([disabled]) {
      background: darken($regent-gray, 10%);
      color: $white;
    }
  }

  &.button-complete {
    background-color: $black-squeeze;
    color: $deep-cerulean;

    &:hover:not(.disabled):not([disabled]) {
      background: darken($black-squeeze, 10%);
    }

    &:hover {
      background-color: $black-squeeze;
    }
  }

  &.button-remove {
    background-color: $valencia;

    &:hover:not(.disabled):not([disabled]) {
      background: darken($valencia, 10%);
    }

    &:hover {
      background-color: $valencia;
    }
  }

  &.button-icon {
    background: transparent;
    padding: 0;
    margin: 0;

    &.button-small {
      height: 34px;
      width: 34px;
    }

    &.button-large {
      height: 50px;
      width: 50px;
    }

    .icon {
      @extend .u-p-0;
      @extend .u-m-0;

      svg {
        width: 65%;
        height: 65%;
        margin: 17.5%;
      }
    }

    $colors: (
      'primary': $emerald,
      'white': $regent-gray,
      'blue': $deep-cerulean,
      'purple': $purple,
      'cancel': $regent-gray,
      'draft': $regent-gray,
      'complete': $black-squeeze,
      'remove': $valencia,
    );

    transition: border 0.2s ease-in-out;

    &:hover:not(.disabled):not([disabled]) {
      background: transparent;
    }

    // build icon button styles for color mixin
    @each $key, $color in $colors {
      &.button-#{$key} {
        color: $color;

        &:hover:not(.disabled):not([disabled]) {
          color: darken($color, 20%);
        }
      }
    }
  }

  &.button-text {
    background: transparent;
    color: $dusty-purple;
    font-size: theme('fontSize.14');
    font-weight: $fw-bold;
    height: auto;
    padding: 0;
    text-transform: none;
    width: auto;

    &:hover:not(.disabled):not([disabled]) {
      background: transparent;
      color: $dusty-purple;
    }

    &.button-primary {
      color: $emerald;
    }

    &.button-secondary {
      color: $deep-cerulean;
    }

    &.button-white {
      color: $regent-gray;
    }

    &.button-blue {
      color: $deep-cerulean;
    }

    &.button-purple {
      color: $purple;
    }

    &.button-cancel {
      color: $regent-gray;
    }

    &.button-draft {
      color: $regent-gray;
    }

    &.button-complete {
      color: $black-squeeze;
    }

    &.button-remove {
      color: $valencia;
    }
  }

  &.button-refer,
  &.button-express-interest,
  &.button-withdraw-interest {
    @include for-size(phone-only) {
      width: 100%;
    }
    background-color: $purple;
    border: 0;
    letter-spacing: normal;
    margin-right: 20px;
    padding: 0 20px;
    width: 190px;
  }

  &.button-withdraw-interest {
    background-color: $deep-cerulean;
  }

  &.button-express-interest,
  &.button-orange {
    background-color: $orange;
    margin-right: 20px;

    &:hover:not(.disabled):not([disabled]) {
      background-color: darken($orange, 10%);
    }
  }

  &.saving,
  &.saving:hover {
    background-color: $emperor;
    padding-left: 40px;
  }

  &.button-success {
    background-image: url('https://images.onaliro.com/client/icon_check_white.png');
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
  }

  /**
    * Button loader
   */
  .button-loader {
    margin: 0 1rem;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }

    svg {
      animation: spin 2s linear infinite;
    }
  }
}

button:not(.switch-root):not([role='checkbox']):not(.unstyled-button):not(.tox-tbtn):not([class^='Toastify']) {
  line-height: 1;
}

button.button-advocacy {
  padding-left: 50px;

  img {
    left: 15px;
    position: absolute;
    top: 5px;
    width: 24px;
  }
}

.draft-button-container,
.publish-button-container {
  text-align: right;
}

.draft-button-container {
  margin-top: 25px;
}

.publish-button-container {
  margin-top: 10px;
}

.draft-button-container a,
.publish-button-container input {
  width: 90%;
}

.save-changes {
  margin-bottom: 0;
  margin-right: 0;
  position: relative;

  &.saving {
    background-color: $regent-gray;
  }

  .progress {
    left: 9px;
    position: absolute;
    top: 6px;
  }
}

.dropdown-container {
  position: relative;

  .button.button-with-dropdown {
    padding-top: 0;
    padding-bottom: 0;

    .icon.button-dropdown-icon {
      padding-top: 8px;
      padding-left: 7px;
      padding-right: 2px;
    }
  }

  .button-dropdown {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 34px;
    padding-top: 5px;

    &.open {
      display: flex;
      flex-direction: column;
    }
  }
}
