  .aliro-header {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  left: 0;
  margin: 0 auto;
  padding-top: 0;
  position: fixed;
  top: 0;
  transition: box-shadow 0.5s;
  width: 100%;
  z-index: 99;

  .aliro-header-wrapper {
    max-height: 55px;
    min-height: 55px;
    padding-bottom: 6px;
    padding-top: 12px;
    > div {
      @include for-size(phone-only) {
        padding-bottom: 3px;
        padding-top: 6px;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      width: 90%;
    }
  }

  .logo-container {
    .home-logo {
      @include for-size(phone-only) {
        max-height: 36px;
        max-width: 160px;
      }
      max-height: 50px;
      width: auto;
    }
  }

  .control-container {
    @include for-size(tablet-landscape-up) {
      width: auto;
    }
    align-items: center;
    display: flex;
    justify-content: right;
    width: auto;

    .mobile-search-toggle {
      @include for-size(tablet-landscape-up) {
        display: none;
      }
      display: block;
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-right: 1rem;
    }

    .job-search-bar {
      display: none;

      @include for-size(tablet-landscape-up) {
        align-items: center;
        display: flex;
        margin: 0 20px 0 0;

        .search-bar-input {
          border: 0;
          border-radius: 8px;
          font-family: 'Karla', sans-serif;
          font-size: theme('fontSize.14');
          height: 34px;
          line-height: 1.4rem;
          margin: 0;
          width: 210px;
          margin-left: 10px;
        }

        .button {
          font-size: theme('fontSize.14');
          margin: 0 0 0 8px;
          padding-left: 16px;
          padding-right: 16px;
          width: auto;
        }
      }

      &.hide {
        display: none;
      }
    }

    .header-nav {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      .company-dashboard-menu {
        display: flex;
        flex-direction: row;

        div {
          padding: 0 28px 0 0;

          a {
            color: $tundora;
            font-weight: $fw-light;

            &.active {
              font-weight: $fw-bold;
            }
          }

          a:hover {
            text-decoration: none;
          }
        }
      }

      .company-dashboard-menu.light div a {
        color: $white;
      }

      .company-dashboard-menu.dark div a {
        color: $darkest-gray;
      }

      fieldset {
        margin: 0 0 0 22px;
        text-align: right;
      }
    }
  }

  &.covering-content {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .open-menu {
    display: none;
  }

  .job-status,
  .profile-status {
    background: $wild-sand;
    color: $tundora;
    font-family: 'Karla', sans-serif;
    font-size: theme('fontSize.14');
    height: 40px;
    line-height: 2.8;
    .published {
      color: $emerald;
    }

    .change-status-unsaved {
      font-style: italic;
      margin-left: 18px;
    }

    .change-status-errors {
      background-image: url('https://images.onaliro.com/client/icon_alert.png');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 16px;
      color: $valencia;
      margin-left: 20px;
      padding-left: 20px;
    }
  }

  .loading-bar {
    background: $deep-cerulean;
    bottom: -2px;
    height: 2px;
    left: 0;
    position: absolute;
    width: 0;
    &.active {
      transition: width 8s;
      width: 80%;
      z-index: 150;
    }
  }
}

.search-bar-input:not(:valid) ~ .clear {
  display: none;
}

.dynamic-bar {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 60px;

  &.show-job-title {
    height: 76px;
  }

  > div {
    align-items: center;
    margin: 0 auto;
    width: 90%;
  }

  div.row-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .jobInfo {
      margin-left: 16px;
      margin-top: 4px;

      .header-job-title {
        display: inline-block;
        font-size: theme('fontSize.16');
        line-height: 2rem;
        margin-right: 10px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .header-req-id {
        color: $deep-cerulean;
        display: inline-block;
        font-size: theme('fontSize.16');
        line-height: 2rem;
        margin-right: 10px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .back {
    background: transparent url('https://images.onaliro.com/client/chevron-back.svg') no-repeat 0/8px 16px;
    float: left;
    height: 28px;
    width: 16px;
  }

  .title {
    color: $boulder;
    font-size: 1.8em;
    line-height: 1em;
  }

  .custom {
    .container {
      @include for-size(phone-only) {
        padding: 0;
      }

      .row {
        align-items: center;
        display: flex;

        label {
          display: inline;
        }

        input,
        select {
          @include for-size(desktop-up) {
            height: 34px;
            margin: 0 0 0 10px;
            padding: 0 0 0 10px;
          }
          font-size: theme('fontSize.14');
          height: 24px;
          line-height: 1.4rem;
          margin: 2px 0 0 10px;
          padding: 0 10px 0 10px;
        }

        select {
          @include for-size(tablet-landscape-up) {
            width: 180px;
          }
          font-size: theme('fontSize.14');
          line-height: 1.4rem;
          width: 140px;
        }

        button {
          @include for-size(tablet-landscape-up) {
            height: 34px;
            margin: 0 0 0 10px;
          }
          height: 24px;
          margin: 2px 0 0 10px;
        }

        .dashboard-date {
          @include for-size(desktop-up) {
            background: $white url('https://images.onaliro.com/client/icons/calendar.png') no-repeat scroll;
            background-position: right 10px center;
            background-size: 24px;
            padding: 0 6px 0 10px;
          }

          background: none;
          cursor: pointer;
        }

        .react-datepicker__navigation {
          height: 0;
          width: 0;
        }

        #search-q {
          @include for-size(tablet-landscape-up) {
            width: 360px;
          }
          width: 220px;
        }

        .search-button {
          @include for-size(tablet-landscape-up) {
            height: 34px;
            line-height: 3.1rem;
            width: auto;
          }
          height: 24px;
          line-height: 2.3rem;
          margin-bottom: 0;
          margin-left: 10px;
          width: 220px;
        }

        .filterStatus {
          width: 140px;
        }

        form {
          @include for-size(tablet-landscape-up) {
            align-items: center;
            display: flex;
            margin: 0 20px 0 0;
          }
          margin: 0 0 0 20px;
        }
      }
    }
  }
}

.subnav-links {
  margin-top: 40px;

  li {
    list-style-type: none;
    margin-bottom: 1.8rem;

    a {
      font-size: theme('fontSize.16');
      line-height: normal;

      &.active {
        color: $tundora;
      }

      &.diversity,
      &.general,
      &.military,
      &.work,
      &.education,
      &.certifications,
      &.resume {
        @include for-size(tablet-landscape-up) {
          background-size: 20px 20px;
        }
        background-repeat: no-repeat;
        background-size: 20px 19px;
        padding-left: 26px;
      }

      &.complete {
        background-image: url('https://images.onaliro.com/client/file-complete.svg');
      }

      &.partial {
        background-image: url('https://images.onaliro.com/client/file-partial.svg');
      }

      &.error {
        background-image: url('https://images.onaliro.com/client/file-error.svg');
      }
    }
  }
}
