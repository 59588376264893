.tooltip-content {
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  max-width: 280px;
  margin: 0 20px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.tooltip-content:not(.dark) {
  border: 1px solid #f0f0f0;
  background: theme('colors.blue.150');
}
.tooltip-content.dark {
  border: 1px solid #323232;
  background: #424242;
  color: white;
}

.tooltip-arrow {
  fill: #f0f0f0;
}
.dark .tooltip-arrow {
  fill: #424242;
}

div {
  button.unstyled-button.tooltip-trigger {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: initial;
    vertical-align: middle;

    &:hover {
      text-decoration: underline;
    }
  }

  button.unstyled-button.tooltip-trigger:has(div.dropdown-item) {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.tooltip-content[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.tooltip-content[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.tooltip-content[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.tooltip-content[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
