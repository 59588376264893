// Icon sizes - image size and font size (for fallback icon)
$avatarSizes: (
    ("xs" 25px),
    ("sm" 40px),
    ("md" 75px),
    ("lg" 120px),
    ("xl" 150px)
);

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;

  &.bordered {
    border: 2px solid $tundora;
    border-radius: 50%;
  }

  @each $size, $imageSize in $avatarSizes {
    &.avatar-#{$size} {
      height: $imageSize;
      width: $imageSize;
      max-height: $imageSize;
      max-width: $imageSize;

      .icon {
        height: calc($imageSize * 0.9);
        width: calc($imageSize * 0.9);
        max-height: calc($imageSize * 0.9);
        max-width: calc($imageSize * 0.9);
      }
    }
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }

  .icon {
    margin: 0;
    padding: 0;
    color: $tundora;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 85%;
      width: 85%;
    }
  }
}

.change-avatar-wrapper {
  position: relative;

  &.clickable > .icon, .icon > svg, .avatar > img {
    pointer-events: auto;
    cursor: pointer;
  }

  .icon {
    position: absolute;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    color: $dusky-blue;
    transition: transform .4s ease-in-out;

    svg {
      height: 100%;
      width: 100%;
    }

    &:hover {
      transform: rotate(180deg);
    }
  }
}
