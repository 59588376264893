// Colors
$emerald: #9bd816;
$sea-green: #0d4608;
$deep-green: #013806;
$green-cyan: #346653;
$pale-green: #e7fff6;
$deeper-green: #002200;
$regent-gray: #8795a4;
$deep-cerulean: #0086a7;
$acqua-squeeze: #e9f4f6;
$valencia: #c20101;
$white: #fff;
$black: #000;
$off-black: #020202;
$purple: #9b448c;
$orange: #f37d1a;
$black-squeeze: #deedf2;
$gainsboro: #dcdcdc;
$available: #a4d2da;
$tealish-blue: #f2f6fc;
$deep-cerulean-lighter: #7ddae8;
$highlight: #f9f9db;
$tan-highlight: #f2dcc6;
$burnt-orange: #db9e5a;
$dusty-purple: #d9d2d8;
$linkedin-blue: #0073b5;
$facebook-blue: #3662a5;
$dark-slate-grey: #273444;
$apple-gray: #353535;
$deep-blue: #003f72;
$dusky-blue: #426085;
$hawkes-blue: #cce3ff;
$burnt-red: #772432;
$active-link-text: #013a81;
$dark-lime-green: #008a30;
$very-dark-lime-green: #013906;
$switch-green: #4cc785;
$pink: #e20177;
$valencia: #d53d4a;

$bg-color: #fdfcfa;

// Grey Values
$mostly-black: #1e1e1e;
$darkest-gray: #303030;
$dark-gray: #323232;
$tundora: #424242;
$emperor: #505050;
$smoke: #707070;
$boulder: #7a7a7a;
$regent-gray: #8795a4;
$dusty-gray: #979797;
$silver: #c2c2c2;
$alto: #d2d2d2;

$gallery: #edede4;
$grayish-blue: #5d6179;
$wild-sand: #f4f4f4;
$alabaster: #fafafa;
$light-gray: #e6e6e6;
$gray: #e5e5e5;
$dark-white: #f6f6f6;
$off-white: #f0f0f0;
$gray-blue: #717479;
$very-light-gray: #e0e0e0;
$lighter-gray: #d9d9d9;
$darker-gray: #b9b9b9;
$also-gray: #eeeeee;
$dark-grayish-blue: #8798ad;
$very-light-grey: #f1f1f1;
$light-grayish-orange: #e9e4da;
// Font Weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

// Menu
$menu-height: 75px;
$menu-height-mobile: 60px;

// Text Colors
$dark-text: #202020;

// Unused
$selago: #ecf4fc;
$catskill-white: #edf2f6;
$supernova: #fc0;
$purple-lighter: #e3a8e3;

$pill-orange-bg: #ffefe0;
$pill-orange-text: #665234;
$pill-red-bg: #ffdeef;
$pill-red-text: #aa0059;
$pill-green-bg: #e7fff6;
$pill-green-text: #346653;

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

/*
// usage
.my-box {
  padding: 10px;

  @include for-size(desktop-up) {
    padding: 20px;
  }
}
*/
