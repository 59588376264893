.pill {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
  border-radius: 20px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  text-wrap: none;

  &.tight {
    justify-content: space-around;
    border-radius: 2px;
    padding: 0.2rem 0.4rem;
    @include for-size(tablet-landscape-up) {
      padding: 0.4rem 0.8rem;
    }
  }

  button,
  button.button.button-icon {
    height: auto !important;
    padding: 3px 0 3px 3px;
    border: none;
    margin: 0 0 0 5px;
    background-color: transparent;
    vertical-align: middle;
    font-size: theme('fontSize.12');
  }

  &.pill-large {
    font-size: theme('fontSize.14');
    line-height: 20px;
    padding: 8px 16px;
    border-radius: 24px;

    button {
      font-size: theme('fontSize.14');
    }
  }

  &.pill-small {
    font-size: 11px;
    line-height: 12px;
    padding: 3px 6px;
    border-radius: 20px;

    button {
      font-size: 11px;
    }
  }

  &.pill-primary {
    background-color: $emerald;
    color: $white;

    button {
      background-color: transparent;
      color: $white;
    }
  }

  &.pill-secondary {
    background-color: $regent-gray;
    color: $white;

    button {
      background-color: transparent;
      color: $white;
    }
  }

  &.gray {
    background-color: $very-light-grey;
    color: $black;
  }

  &.orange {
    background-color: $pill-orange-bg;
    color: $pill-orange-text;
  }

  &.red {
    background-color: $pill-red-bg;
    color: $pill-red-text;
  }

  &.green {
    background-color: $pill-green-bg;
    color: $pill-green-text;
  }

  &.lilac {
    background-color: #f5f2fc;
    color: #5a4b7d;
  }
}
