.onboarding-tabs {
  .tabs {
    .links {
      width: 100%;

      li {
        width: 50%;
      }
    }

    .sections {
      @extend .u-m-0;
      @extend .center;
    }
  }
}

.app-wrapper.sign-up-page, .app-wrapper.login-page, .app-wrapper.forgot-password-page {
  background: #F9F7F2;

  header.aliro-header {
    background: #F9F7F2;
    box-shadow: none;
  }

  @include on-mobile {
    .content-wrapper {
      flex: auto;
      margin-left: 0;
      margin-right: 0;
      width: 100%;

      .main-content {
        height: 100%;
      }
    }
  }

  .u-box.card, .u-box.card.card-vertical {
    border-radius: 25px;
    padding: 1.5rem 4rem;
    margin-bottom: 5rem;
  }

  @include on-mobile {
    .u-box.card, .u-box.card.card-vertical {
      margin-bottom: 0;
    }
  }

  form {
    margin-bottom: 0;
  }
}
