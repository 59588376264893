.card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  padding: 0;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 2px rgba(184, 184, 179, 0.2);
  border-radius: 25px;

  img.card-image {
    flex: 0;
    width: 30%;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 16px 0 0 16px;
  }

  .card-content {
    flex: 1;
    @extend .u-p-2;
    @extend .u-pl-4;
    @extend .u-pr-4;

    .card-title {
      @extend .u-mb-2;
      font-family: Roboto sans-serif;
      font-style: normal;
      font-weight: 400;
      color: $sea-green;
      font-size: 2rem;
    }

    .card-text {
      color: #000000;
      font-size: 1.5rem;
      font-family: Roboto sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    .card-footer {
      .card-link {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        vertical-align: middle;
        color: #013806;

        .icon {
          line-height: normal;
        }
      }
    }
  }

  &.card-vertical {
    flex-direction: column;
    flex-wrap: nowrap;
    @extend .u-p-4;

    img.card-image {
      width: 100%;
      height: auto;
      aspect-ratio: auto;
      border-radius: 16px;
      flex: 1;
      @extend .u-mb-4;
    }

    .card-content {
      @extend .u-p-0;
    }
  }

  &.card-small {
    padding: 2rem 2rem 2rem!important;
    margin-bottom: 1rem!important;
    &:last-child {
      margin-bottom: 2rem!important;
    }
  }

  &.card-xsmall {
    padding: 1rem 2rem!important;
    margin-bottom: 0.5rem!important;
  }
}

.card-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.person-card.card-vertical {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-style: normal;

  border-radius: 24px;

  .card-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  img.card-image {
    border-radius: 24px;
  }

  .card-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
