@keyframes menuBounceDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: translate3d(0, 0, 0);
  }

  85% {
    transform: translate3d(0, calc(100% + 10px), 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes menuBounceUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: translate3d(0, 100%, 0);
  }

  20% {
    transform: translate3d(0, calc(100% + 10px), 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

// Spinner
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.progress {
  position: relative;
  display: inline-block;
  width: 5em;
  height: 5em;
  font-size: theme('fontSize.12');
  text-indent: 999em;
  overflow: hidden;
  animation: spin 1s infinite steps(8);
}

.small.progress {
  font-size: 0.4rem;
}

.large.progress {
  font-size: theme('fontSize.24');
}

.progress:before,
.progress:after,
.progress > div:before,
.progress > div:after {
  content: '';
  position: absolute;
  top: 0;
  left: 2.25em; /* (container width - part width)/2  */
  width: 0.5em;
  height: 1.5em;
  border-radius: 0.2em;
  background: $wild-sand;
  box-shadow: 0 3.5em $wild-sand; /* container height - part height */
  transform-origin: 50% 2.5em; /* container height / 2 */
}

.progress:before {
  background: $emperor;
}

.progress:after {
  transform: rotate(-45deg);
  background: $boulder;
}

.progress > div:before {
  transform: rotate(-90deg);
  background: $dusty-gray;
}

.progress > div:after {
  transform: rotate(-135deg);
  background: $alto;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
}
