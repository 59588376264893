$shadow-key-umbra-opacity: 0.20 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

.enrolment {
  .title {
    @extend .u-mt-4;
    @extend .u-mb-4;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  .stepper-wrapper {
    @extend .u-mt-4;
    @extend .u-mb-4;
    @extend .u-pt-4;
    @extend .u-pl-4;
    @extend .u-pr-4;
    background-color: $white;
    box-shadow: 0px 4px 40px 10px #E9E4DA;
    border-radius: 25px 25px 25px 25px;
    @include on-mobile {
      border-radius: 25px 25px 0px 0px;
    }

    .stepper-head {
      .stepper-step {
        &.is-active {
          .stepper-label {
            color: $black;
          }
        }

        .stepper-label {
          color: $boulder;
        }
      }
    }

    @include on-mobile {
      &.open {
        height: 100%;
      }
    }

    .stepper-body.open {
      height: 100%;

      @include on-mobile {
        background-color: $bg-color;
      }
    }

    .enrolment-step-content {
      @extend .u-p-4;

      @include on-mobile {
        background-color: $white;
        box-shadow: 0px 4px 40px 10px rgba(233, 228, 218, 0.7);
        border-radius: 0px 25px 25px 0px;
      }

      .radio-group {
        @extend .u-mb-0;
      }
    }

    .enrolment-step-actions {
      @extend .u-p-4;

      button, .button {
        @extend .u-m-0;
        @extend .u-mb-0;
      }
    }

    @include on-mobile {
      //box-shadow: 0 9px 46px 8px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
      //0 11px 15px -7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
      //0 24px 38px 3px rgba(0, 0, 0, $shadow-key-umbra-opacity);

      border-top: 1px solid $alto;
    }
  }

  &.enrolment-info {
    h1 {
      font-family: 'Merriweather', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;

      color: #013906;

    }

    h2 {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #013906;
      margin-bottom: 1.5rem;
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }

    .card {
      padding: 6rem;

      @include on-mobile {
        padding: 3rem;
      }
    }

    .documents-needed {
      width: 70%;
      @include on-mobile {
        width: 90%;
      }
      margin: 0 auto;

      ul {
        list-style: none;
        background-color: $gallery;
        padding: 2rem 4rem;

        li {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: $black;
        }

        li::before {
          content: "•";
          color: black;
          padding-right: 10px;
        }
      }
    }
  }
}

.sample-section {
  border-bottom: 1px solid $tundora;
  padding-bottom: 3rem;
  margin-bottom: 3rem;

  a {
    color: black;
    cursor: pointer;

    &:hover {
      color: $deep-green;
    }
  }
}

.summary-section {
  @extend .u-pb-4;
  @extend .u-mb-0;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 0;

  &:not(:last-child) {
    @extend .u-mb-4;
    border-bottom: 1px solid $sea-green;
  }

  .summary-section-head {
    @extend .u-mb-2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .summary-section-edit-button {
      .icon {
        color: $black;
        font-size: 2.5rem;
      }
    }
  }

  .summary-section-body {
    .summary-section-item {
      @extend .u-mb-4;

      label {
        @extend .u-m-0;
        @extend .u-mb-0;
        font-size: 1.4rem;
        line-height: 1.4rem;
      }

      span {
        font-size: 1.2rem;

      }
    }
  }

  .number-circle {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.6rem;
    border-radius: 50%;
    background-color: $silver;
    color: $white;
    text-align: center;
  }
}

.u-bg-darker {
  background-color: darken($bg-color, 5%);
}
