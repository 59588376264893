.app-wrapper.subnav-page,
.subnav-page {
  .subnav-container {
    p {
      font-family: "Roboto", sans-serif;
    }
  }

  .subnav-content-wrapper {
    max-width: 100%;
    max-height: calc(100vh - 4rem - $menu-height);
    @include for-size(phone-only) {
      max-height: calc(100vh - 4rem - $menu-height-mobile);
    }
  }
}
