.form-input-user-document-container {
  display: flex;
  flex-direction: column;
  @extend .u-mb-4;

  label {
    @extend .u-mb-1;
    font-size: 1.5rem;
  }

  p {
    margin: 0;
    color: $black;
    font-size: 1.2rem;

    &.help-text {
      color: $alto;
    }
  }
}

.document-summary {
  span.icon {
    @extend .u-m-2;
    color: $emerald;

    font-size: 3rem !important;
  }

  button.button-icon {
    @extend .u-m-0;
    @extend .u-p-0;

    span.icon, span.icon svg {
      @extend .u-m-0;
      @extend .u-p-0;

      color: $sea-green !important;
    }
  }

  p.file-name {
    @extend .u-m-0;
    @extend .u-p-0;
    flex: 1;
    font-size: 1.4rem;
  }

  button.button-icon {
    @extend .u-m-0;
    @extend .u-p-0;
    background-color: transparent;

    .icon {
      font-size: 0.5rem;
      color: $black;
    }
  }
}
