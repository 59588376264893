// Breakpoints
$bp-phone-max: 360px;
$bp-tablet-max: 768px;

.pagination {
  font-size: theme('fontSize.14');
  width: 100%;

  .results-per-page {
    color: $tundora; // Drop down color
    float: left;
    margin-left: -13px;
    margin-top: -5px;
    min-width: 240px;
    .menu-title,
    li {
      padding: 0 13px;
    }

    .menu-title {
      display: inline-block;
    }
  }

  ul {
    display: flex;
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: $bp-tablet-max) {
      display: block;
      margin: 0 20px;
      width: auto;
    }
  }

  li {
    flex: 1 1 auto;
    margin-bottom: 0;
    @media (min-width: $bp-tablet-max) {
      display: block;
    }

    &.disabled {
      cursor: default;
      opacity: 1;
      .page {
        opacity: 0.2;
      }
    }
  }

  .page-count {
    color: $tundora;
    float: left;
    line-height: 30px;
    margin-top: 5px;

    @media (min-width: $bp-tablet-max) {
      float: right;
      margin-top: 0;
    }
  }

  .page-arrow,
  .page-number {
    background-color: rgba(0, 134, 167, 0.1);
    border: 0;
    clear: none;
    color: $deep-cerulean;
    cursor: pointer;
    float: left;
    font-family: 'Karla', sans-serif;
    font-size: theme('fontSize.20');
    height: 40px;
    line-height: 2;
    margin-left: 1px;
    margin-right: 1px;
    text-align: center;
    width: 40px;

    @media (min-width: $bp-phone-max) {
      border-radius: 6px;
      font-size: theme('fontSize.18');
      height: 40px;
      line-height: 2.25;
      width: 30px;
    }

    @media (min-width: $bp-tablet-max) {
      border-radius: 4px;
      font-size: theme('fontSize.14');
      height: 25px;
      line-height: 1.85;
      width: 25px;
    }
  }

  .page-arrow {
    margin-left: 10px;
    &:first-of-type {
      margin-left: 0;
      margin-right: 10px;
    }

    img {
      display: block;
      left: 50%;
      position: relative;
      top: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: $bp-tablet-max) {
        height: 12px;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 1;
      img {
        opacity: 0.4;
      }
    }
  }

  .selected-page {
    background-color: $active-link-text;
    color: $white;
  }
}

.my-jobs-page .pagination li:first-child {
  border-top: 0;
}
