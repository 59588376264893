@mixin generate($prefix, $property, $withAuto: true) {
  // List of sizes to generate for each
  $sizes: [0, 0.25, 0.5, 1, 1.5, 3];
  // Spacing to multiply the sizes by
  $spacing: 1rem;

  // Loop through all of the sizes(we use @for rather than @each, as we want access to the index)
  @for $i from 1 through length($sizes) {
    // Get the size for the current index
    $size: nth($sizes, $i);

    // Create the rule
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size;
    }
  }

  @if $withAuto {
    .#{$prefix}-auto {
      #{$property}: auto;
    }
  }
}

@include generate(u-m, margin);
@include generate(u-ml, margin-left);
@include generate(u-mr, margin-right);
@include generate(u-mb, margin-bottom);
@include generate(u-mt, margin-top);

@include generate(u-p, padding);
@include generate(u-pl, padding-left);
@include generate(u-pr, padding-right);
@include generate(u-pb, padding-bottom);
@include generate(u-pt, padding-top);
