.tabs {
  margin-top: 40px;

  &.no-margin {
    margin: 0;
    ul,
    .sections {
      margin: 0;
    }
  }

  h3 {
    border-bottom: 1px solid $alto;
    margin-bottom: 40px;
    padding-bottom: 10px;
  }

  h5 {
    margin-bottom: 20px;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    list-style: none;
    margin: -20px 0 24px;
    width: 100%;

    li {
      margin: 0;
      position: relative;
      text-align: center;

      &.u-full-width {
        flex: 1;
      }

      button {
        background: $dusty-purple;
        border-radius: 20px;
        color: $purple;
        font-family: sans-serif;
        font-size: 0.8em;
        height: 20px;
        line-height: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 15px;
        width: 20px;

        &:hover {
          background: $purple-lighter;
        }
      }

      a {
        display: inline;
        font-family: 'Inter', serif;
        font-size: theme('fontSize.16');
        margin-bottom: 1rem;
        text-decoration: none;
        font-weight: $fw-bold;
        color: $dark-text;
      }

      &.active {
        border-bottom: 4px solid $dusky-blue;
        a {
          color: $dark-text;
          font-weight: $fw-bold;
        }
      }

      &.hasDelete {
        padding-right: 24px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .sections {
    list-style: none;
    width: auto;

    li {
      display: none;
      margin: 0;

      &.active {
        display: block;
      }
    }
  }
}
