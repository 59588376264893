.admin-page {
  h1 {
    margin-bottom: 2rem;
  }

  h2, h3 {
    margin-bottom: .5rem;
  }

  .card {
    padding: 3rem 6rem;
    margin-bottom: 3rem;
  }

  .dashboard-count-link {
    cursor: pointer;
    text-decoration: none;


    &:hover {
      .dashboard-count {
        text-decoration: none;
        transform: scale(1.02);
        transition: transform 0.2s ease-in-out;
      }
    }
  }

  .dashboard-count {
    @extend .card;
    @extend .u-p-4;
    margin: 0;
    flex: 1;
    flex-direction: column;
    text-align: center;
    vertical-align: middle;

    h1 {
      color: $deep-green;
    }

    h3 {
      color: $black;
    }
  }

  .action-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.pill.candidate-payment-status {
  background-color: transparent;
  border: 0;
  color: $black;

  &.paid {
    color: #008A30;
  }

  &.unpaid {
    color: #FA4772;
  }
}

.pill.candidate-status, .pill.employee-status {
  border-radius: 4px;
  border: 0;
  color: $black;

  &.prospect {
    background-color: #E2F3FF;
  }

  &.admitted {
    background-color: #FFE2F2;
  }

  &.enrolled {
    background-color: #EFF8DC;
  }

  &.waitlist {
    background-color: $burnt-orange;
    color: $white;
  }

  &.active {
    background-color: #EFF8DC;
  }

  &.inactive {
    background-color: #FFE2F2;
  }
}

button.button.button-small.button-icon.bookmark-button {
  background-color: transparent;
  color: $black;
  border: 0;
  margin: 0;
  padding: 0;
  height: auto;

  .icon, .icon svg {
    font-size: 3rem;
    line-height: 0;
    margin: 0;
    padding: 0;
  }

  &.bookmarked {
    color: $emerald;
  }
}
