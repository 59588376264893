.preference {
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-top: 0;
  }

  .preference-actions {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    button {
      all: unset;
      color: $active-link-text;
      cursor: pointer;
      display: flex;
      gap: 0.25em;
      flex-wrap: nowrap;
      white-space:nowrap;

      &:hover {
        text-decoration: underline;
      }

      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }
  }

  .switch-container {
    align-items: flex-start;
    margin: 0;
    width: 80%;

    button {
      margin-top: 0.063em;
      cursor: pointer;
    }

    .sublabel {
      margin: 0;
    }
  }
}
