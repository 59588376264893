/* We're stacking the class name selector to increase the calculated specificity so we can easily override the existing, and unwanted, styles */
.styled-radio.styled-radio.styled-radio.styled-radio.styled-radio.styled-radio.styled-radio.styled-radio.styled-radio.styled-radio {
  all: unset;
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin: 0;
  max-width: 100%;
  width: max-content;

  input[type=radio] {
    cursor: pointer;
    height: 20px;
    margin: 2px 0 0 0;
    width: 20px;
  }

  span {
    width: calc(100% - 34px);
  }

  &:has(input[type=radio]:focus-visible) {
    outline: 2px solid $deep-cerulean;
    outline-offset: 4px;
  }
}
