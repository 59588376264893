.app-wrapper.subnav-page.candidate-portal-page, .app-wrapper.subnav-page.advisor-portal-page, .admin-page {
  .card {
    padding: 3rem 6rem;
    margin-bottom: 3rem;

    @include on-mobile {
      padding: 1.5rem 3rem;
    }
  }

  .picture-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .picture-actions {
      @extend .u-ml-4;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .button {
        @extend .u-mb-2;
        width: 100%;
      }
    }
  }

  .dashboard-link-card-grid {
    /** square cards **/
    .card.candidate-portal-dashboard-link {
      margin: 0;
      color: $emerald;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 24px;

      .icon {
        line-height: 0;
        font-size: 10rem;
        @extend .u-mb-2;

        @include on-mobile {
          font-size: 6rem;
        }

        svg {
          line-height: 0;
        }
      }

      .title {
        font-size: 2rem;
      }
    }
  }

  .course-container {
    p {
      margin-bottom: 0;

      &.course-name {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.2rem;
      }

      &.course-description {
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 2.2rem;
      }

      &.course-subject {}

      &.course-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        a {
          color: theme('colors.blue.500');
          &:hover {
            text-decoration: underline;
          }

          &:not(:last-child) {
            margin-right: 0.5rem;

            &:after {
              content: '|';
              margin-left: 0.5rem;
            }
          }
        }
      }

      &.course-progress {
        button.button.button-small {
          line-height: 2rem;
          font-size: 1.5rem;
          font-weight: 400;
          margin: 0 0.5rem;
          padding: 0;
          border: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .resume-section, .document-section {
    .upload-container, .resume-container, .document-summary {
      @extend .card;
      @extend .card-vertical;
      @extend .card-small;
      flex: 1;
    }

    form {
      padding: 0;
      margin: 0;
    }

    p, h4, h5, h6 {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
    }

    p {
      font-weight: 400;
      line-height: 28px;
      font-size: 1.8rem;

      @extend .u-p-0;
      @extend .u-m-0;

      &.file-uploaded {
        font-weight: 800;
        font-size: 1.2rem;
      }
    }

    h4 {
      font-weight: 400;
      font-size: 2.2rem;
      line-height: 26px;
      @extend .u-mb-4;

    }

    h5 {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 24px;
      color: #0D4608;
      @extend .u-mb-4;
    }

    h6 {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 24px;
      color: #10192E;
      margin: 0 0 1rem;

      span.resume-head-date {
        color: #707070;
      }
    }
  }

  .settings-section {
    .u-box.card, .u-box.card.card-vertical {
      border-radius: 25px;
      padding: 1.5rem 4rem;
      margin: 0 0 5rem;
    }
  }

  .datagrid-container {
    background-color: transparent;
    color: $off-black;

    .datagrid-inner-container {
      background-color: transparent;
      box-shadow: none;
      padding: 0;

      .datagrid-view-link {
        padding: 0 2rem;
        color: $emerald;

        .icon, .icon svg {
          line-height: 0;
        }
      }
    }


    table {
      background: transparent;

      thead {
        border: none;
        background: transparent;

        tr {
          th {
            background-color: #013806;
            color: white;
          }
        }
      }

      tbody {
        border: none;
        background: transparent;
        box-shadow: 2px 2px 10px 2px rgba(184, 184, 179, 0.2);

        &::before {
          line-height: 0;
        }

        button {
          margin: 0;
        }

        tr {
          color: $off-black;

          td {
            border-bottom: 1px solid $very-light-gray !important;
          }
        }
      }

      tbody, thead {
        tr {
          th, td {
            border: none;
            padding: 1rem 2rem;
            text-align: left;
            font-size: 1.5rem;
          }
        }
      }
    }

    thead tr:nth-child(2) {
      th {
        background-color: white;
        border-color: $dusky-blue;
      }
    }

    .pagination {
      margin-top: 2.5rem;

      .page-count, .page-number, .menu .menu-title {
        font-size: 1.5rem;
        color: $black;
      }

      .page-count {
        margin-top: 2px;
      }

      ul {
        display: table-row;

        li {
          display: table-cell;
          vertical-align: middle;

          .page {
            margin: 0;
            padding: 0;
          }

          &.page-number {
            margin-top: 4px;
            background-color: transparent;
            cursor: pointer;

            &.selected-page {
              color: $emerald;
              cursor: default;
            }
          }

          &.page-arrow.next, &.page-arrow.prev {
            padding: 5px;
            border-radius: 50%;
            background-color: $emerald;
            color: $white;
            line-height: 0;

            &.disabled {
              background-color: $alto;
            }
          }
        }
      }
    }
  }

  .company-logo {
    border-radius: 0.4rem;
    border: 1px $very-light-gray solid;
    aspect-ratio: 1/1;
    height: 5rem;

    @media screen and (min-width: 640px) {
      height: 8rem;
    }
    object-fit: cover;
  }

  .subnav-container .subnav li {
    //&.jobs {
    //  a span.icon {
    //    font-size: 2rem;
    //    margin-left: 0.25rem;
    //    margin-right: 2.25rem;
    //  }
    //}

    &.settings {
      a span.icon {
        font-size: 2.75rem;
        margin-right: 1.75rem;
      }
    }

    &.logout {
      a span.icon {
        font-size: 2.25rem;
        margin-left: 0.25rem;
        margin-right: 2rem;
      }
    }

    &.resume a span.icon {
      font-size: 2.75rem;
    }
  }
}

.card.team-candidate-card .card-content {
  @extend .u-p-4;
  @extend .u-pb-0;
}

div.team-candidate-modal-content {
  h4, h6, p {
    text-align: left;
  }
}

div.modal.job-detail-modal {
  color: $off-black;
  font-size: 1.5rem;

  table {
    margin-bottom: 0.5rem;

    td {
      border: none;
      padding: 0.25rem 0;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
