/* We're stacking the class name selector to increase the calculated specificity so we can easily override the existing, and unwanted, styles */
.styled-input.styled-input.styled-input.styled-input.styled-input.styled-input.styled-input.styled-input.styled-input.styled-input {
  all: unset;
  background-color: $dark-white;
  border-radius: 4px;
  box-sizing: border-box;
  color: $smoke;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  padding: 17px 10px 13px 10px;
  position: relative;
  width: 100%;

  &::-webkit-datetime-edit {
    line-height: 1;
    padding: 0;
    margin-bottom: -2px;
  }

  &::-webkit-date-and-time-value {
    height: 20px;
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
}