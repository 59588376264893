.app-wrapper.subnav-page,
.subnav-page {
  background-color: $bg-color;
  flex: 1;
  max-height: none;

  > div {
    width: 100%;
  }

  .subnav-container {
    @extend .u-m-0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 100%;

    .subnav {
      padding-top: 4rem;
      padding-left: 0;
      padding-right: 0;
      width: 30%;
      max-width: 400px;
      height: calc(100vh - 4rem - $menu-height);

      li {
        a .icon {
          font-size: theme('fontSize.24');
        }
      }

      // on sm down, hide the subnav
      @include on-mobile {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;

        z-index: 10;
        background-color: $bg-color;
        margin: 0;
        margin-top: $menu-height-mobile;
        padding: 2rem;

        &.mobile-subnav-open {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        li {
          //flex: 1
        }
      }
    }

    .subnav-content-wrapper {
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      flex: 1;
      padding: 4rem 4rem 0;

      @include on-mobile {
        padding-top: 2rem;
      }

      .subnav-content {
        max-width: 100%;

        @include for-size(tablet-landscape-up) {
          max-width: 900px;
        }

        &.fluid {
          max-width: 95%;
        }

        margin: 0 auto;
      }
    }

    h1 {
      font-family: 'Merriweather', serif;
      font-style: normal;
      font-weight: 700;
      font-size: theme('fontSize.32');
      line-height: 40px;
      color: $very-dark-lime-green;
      @extend .u-mb-4;

      .inline-subtitle {
        margin: 0 0 0 0.75rem;

        font-family: 'Merriweather', serif;
        font-style: normal;
        font-weight: 700;
        font-size: theme('fontSize.20');
        line-height: 25px;

        color: $very-dark-lime-green;
      }
    }

    p {
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 400;
      font-size: theme('fontSize.16');
      line-height: 28px;

      &:not(.text-error,.error-message) {
        color: $off-black;
      }
    }

    .picture-section {
      margin: 0 0 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .picture-actions {
        @extend .u-ml-4;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .button {
          @extend .u-mb-2;
          width: 100%;
        }
      }
    }
  }
}

.subnav {
  padding: 0 4rem;
  margin: 0;
  border: 0;
  background: $white;
  box-shadow: 0px 4px 40px 10px $light-grayish-orange;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    width: 80%;
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    padding: 0.75rem;
    margin: 0.5rem 1rem;

    &.active,
    &:hover {
      background: rgba(237, 237, 228, 0.6);
      color: $tundora;
      transition: background-color 0.5s ease-in-out;

      a .icon {
        color: $dark-lime-green;
      }
    }

    &.active {
      cursor: default;
    }

    &.header {
      @extend .u-mr-0;
      font-weight: bold;
      font-size: theme('fontSize.20');
      &:hover {
        background-color: transparent;
        color: $tundora;
        cursor: default;
      }
    }

    a,
    a span.label,
    a .icon,
    a .icon svg {
      line-height: 0;
    }

    a {
      @extend .u-m-4;
      @extend .u-p-0;
      vertical-align: middle;
      color: $tundora;

      span.label {
        @extend .u-ml-2;
        font-family: 'Inter', serif;
        color: $off-black;
        font-style: normal;
        font-weight: 400;
        font-size: theme('fontSize.20');
        vertical-align: middle;
      }

      .icon {
        @extend .u-mr-4;
        color: $emerald;
      }
    }
  }
}
