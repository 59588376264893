/* We're stacking the class name selector to increase the calculated specificity so we can easily override the existing, and unwanted, styles */
.styled-button.styled-button.styled-button.styled-button.styled-button.styled-button.styled-button.styled-button.styled-button.styled-button {
  all: unset;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
  height: max-content;
  line-height: normal;
  padding: 10px 16px;
  text-align: center;
  width: max-content;

  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  &:focus-visible {
    outline: 2px solid $deep-cerulean;
    outline-offset: 4px;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &.styled-button--wrap-only {
    padding: 0;
    background-color: transparent;
  }

  &.styled-button--primary {
    background-color: $deep-cerulean;
    color: $white;
  }

  &.styled-button--secondary {
    background-color: $hawkes-blue;
    color: $deep-cerulean;
  }

  &.styled-button--tertiary {
    background-color: transparent;
    color: $deep-cerulean;

    &-cancel {
      background-color: transparent;
      color: $pink;
    }
  }

  &.styled-button--quaternary {
    background-color: $white;
    border: 1px solid $deep-cerulean;
    color: $deep-cerulean;
    padding: 9px 15px;
  }

  &.styled-button--small {
    padding: 0;
  }
}
