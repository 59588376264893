@mixin tooltip-hover() {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.tooltip {
  background: $deep-cerulean;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  color: $white;
  display: block;
  font-family: 'Karla', san-serif;
  font-size: theme('fontSize.12');
  font-weight: 500;
  line-height: 17px;
  margin-top: 9px;
  opacity: 0;
  padding: 8px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 100%;
  transform: translateY(10px);
  transition: all 0.25s ease-out;
  width: 170px;
  z-index: 100;

  &:before {
    content: ' ';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: -20px;
    width: 100%;
  }

  &:after {
    border-bottom: solid $deep-cerulean 8px;
    border-left: solid transparent 8px;
    border-right: solid transparent 8px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -4px;
    position: absolute;
    top: -8px;
    width: 0;
  }
}
