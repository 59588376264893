/* We're stacking the class name selector to increase the calculated specificity so we can easily override the existing, and unwanted, styles */
.styled-text-area.styled-text-area.styled-text-area.styled-text-area.styled-text-area.styled-text-area.styled-text-area.styled-text-area.styled-text-area.styled-text-area {
  all: unset;
  background-color: $dark-white;
  border-radius: 4px;
  box-sizing: border-box;
  color: $smoke;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  padding: 17px 10px 13px 10px;
  position: relative;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
}