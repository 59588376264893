form {
  label {
    @extend .u-mb-0;
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.form-input-border-bottom {
  border: 0;
  border-radius: 0;
  border-bottom: lighten($alto, 0.8) 1px solid;

  &:focus, &.focus {
    border: 0;
    border-bottom: $sea-green 2px solid;
  }
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='date'],
select, textarea {
  //padding: 1.2rem 1rem;
  @extend .form-input-border-bottom;
  margin-bottom: 0;
  background: transparent;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px,
  5px 5px,
  1px 1.5em;
  background-repeat: no-repeat;

  option {
    color: $tundora;

    &.placeholder {
      color: $silver;
    }
  }
}

.react-select {
  background: transparent;
  font-family: "Roboto", sans-serif;

  .react-select-control {
    min-height: 50px;
    min-width: 150px;
    background: transparent;
    border: 0;
    // This line disable the blue border
    box-shadow: none;
    border-radius: 0;
    border-bottom: lighten($alto, 0.8) 1px solid;

    &:hover {
      border: 0;
      border-bottom: $sea-green 2px solid;
    }
  }

  .react-select-input {
    height: 45px;

    input {
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .react-select-placeholder {
    color: $silver;
  }

  .react-select-menu {
  }

  .react-select-value-container {
    min-height: 50px;
    border: 0;

    > div:first-child {
      width: auto;
      max-width: unset;
      //margin-right: 0.5rem;
    }
  }

  .react-select-indicator-separator {
    display: none;
  }

  .react-select-indicators-container {
    & > div {
      padding-left: 0;
      padding-right: 0;

      color: #808080;
      font-size: 1.75rem;
    }
  }

  .pill {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  //
  //  .react-select-option {
  //    color: $tundora;
  //
  //    &.placeholder {
  //      color: $silver;
  //    }
  //  }
}

.form-textarea-container textarea.form-input {
  &:focus {
    border: $sea-green 1px solid;
  }
}

// *************************************************************
// Styles for the FormPhoneInput component which uses react-phone-input-2
// *************************************************************
.react-tel-input {
  @extend .form-input-border-bottom;
  background: transparent;
  width: auto;

  .flag-dropdown {
    @extend .u-mt-4;
    @extend .u-mb-4;
    @extend .u-pr-1;
    border-right: lighten($alto, 0.8) 1px solid;

    .arrow {
      @extend .u-ml-1;
    }
  }

  input.form-input.form-control {
    border: 0;

    &:focus {
      border: 0;
    }
  }
}


// *************************************************************
// Styles for the FormDateInput component which uses react-multi-date-picker
// *************************************************************
.datepicker-container {
  @extend .u-mb-4;
  position: relative;

  input[type="text"] {
    @extend .u-full-width;
    @extend .form-input-border-bottom;
  }

  .rmdp-container {
    @extend .u-full-width;

    input[type="text"] {
      margin-bottom: 0px;
    }
  }

  .rmdp-wrapper {
    @extend .u-border-radius-0;

    background: $gallery;
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06), 0px 20px 25px -5px rgba(15, 23, 42, 0.1), 0px 10px 10px -5px rgba(15, 23, 42, 0.04);
    border-radius: 16px;

    .rmdp-week-day {
      color: $sea-green;
    }

    .rmdp-day {
      &.rmdp-today span {
        background-color: transparent;
        color: $sea-green;
      }

      &.rmdp-selected span:not(.highlight) {
        background-color: $emerald;
        box-shadow: 0 0 3px #8798ad;
        border-radius: 4px;
        color: $sea-green;
      }

      &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        background-color: $emerald;
        box-shadow: 0 0 3px #8798ad;
        border-radius: 4px;
        color: $sea-green;
      }
    }

    .rmdp-arrow-container {
      .rmdp-arrow {
        border-color: $emerald;
        margin-top: 7px;
      }

      &:hover {
        background-color: $emerald;

        .rmdp-arrow {
          border-color: $gallery;

          &:after {
            background-color: $gallery;
          }
        }

      }
    }
  }

  .rmdp-ep-arrow[direction=bottom] {
    border-top-color: $gallery;

    &:after {
      background-color: $gallery;
      border-color: $gallery;
    }
  }

  button.button {
    margin: 0;
    left: -50px;
    top: 2px;
    line-height: normal;

    .icon {
      line-height: normal;

      svg {
        width: 45%;
        height: 45%;
        margin: 27.5%;
      }
    }
  }
}

// *************************************************************
// Styles for the FormTagInput component which uses react-tag-input
// *************************************************************
.ReactTags__tags {
  //display: flex;
  //flex-wrap: wrap;
  @extend .form-input-border-bottom;

  .ReactTags__selected {

    .tag-wrapper {
      margin-bottom: 0.5rem;

      button.ReactTags__remove {
        background: transparent !important;
        line-height: 0.9!important;
        padding: 1px 4px!important;
      }
    }
  }

  //background: transparent;
  //width: 100%;
  //font-size: 1.2rem;
  //
  //
  //padding: 2px 0 2px 20px;
  //
  //&:not(:first-child) {
  //  padding-left: 0;
  //}
  //
  //.ReactTags__tagInput {
  //  flex-grow: 1;
  //  border: 0;
  //
  //  &:focus {
  //    border: 0;
  //  }
  //}
  //
  input[type="text"].ReactTags__tagInputField,textarea.ReactTags__tagInputField {
    min-width: 50px;
    border-bottom: 0;
    padding-bottom: 4px;
  }
}

.form-input-container, .datepicker-container, .react-tel-input, .radio-group, .form-textarea-container {
  @extend .u-mb-4;
}

.form-textarea-container.tight {
  margin: 0;

  textarea {
    margin: 0;
  }
}

/** tooltip styles */
:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
}
