
.accordion-container {
  /* reset */
  button:not([role='checkbox']),
  h3 {
    all: unset;
  }

  .accordion-root {
    background-color: $white;

    .accordion-item {
      overflow: hidden;
      margin-top: 1px;

      &:not(:last-child) {
        border-bottom: 1px solid $gallery;
      }
    }

    .accordion-item:first-child {
      margin-top: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .accordion-item:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .accordion-item:focus-within {
      position: relative;
      z-index: 1;
    }

    .accordion-header {
      display: flex;
    }

    .accordion-trigger {
      font-family: inherit;
      background-color: transparent;
      padding: 0;
      height: 45px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      line-height: 1;
      color: $dark-text;
    }

    .accordion-content {
      overflow: hidden;
    }

    .accordion-content[data-state='open'] {
      animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    .accordion-content[data-state='closed'] {
      animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    .accordion-chevron {
      transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    .accordion-trigger[data-state='open'] > .accordion-chevron {
      transform: rotate(180deg);
    }

    @keyframes slideDown {
      from {
        height: 0;
      }
      to {
        height: var(--radix-accordion-content-height);
      }
    }

    @keyframes slideUp {
      from {
        height: var(--radix-accordion-content-height);
      }
      to {
        height: 0;
      }
    }

  }
}
