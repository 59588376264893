.media-manager {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.media-folders, .media-items {
  .media-folder, .media-item {
    @extend .u-p-3;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: $wild-sand;
    border: 1px $alto solid;
    border-radius: .75rem;
    cursor: pointer;
    transition: all .2s;

    img {
      @extend .u-border-radius-1;
      max-width: 100%;
      max-height: 200px;
      width: auto;
      height: auto;
      margin: auto;
    }

    &:hover {
      background: $wild-sand;
      border: 1px $available solid;
      transform: scale(1.01);
    }
  }
}

.media-item-preview-modal {

}
