.contact-links-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  a.contact-link {
    @extend .u-mb-4;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;

    .icon {
      @extend .u-mr-4;
      line-height: 0;
      font-size: 2rem;

      svg {
        line-height: 0;
      }
    }

    &.whatsapp {
      .icon {
        @extend .u-mr-3;
        color: #25D366;
        font-size: 2.5rem;
      }
    }

    &.phone {
      .icon {
        color: #013806;
      }
    }

    &.email {
      font-size: 16px;

      .icon {
        color: #013806;
      }
    }
  }
}

img.profile-picture {
  @extend .u-m-0;
  @extend .u-p-0;
  flex: 1;
  width: 100%;
  max-width: 100%;
  height: auto;
  box-shadow: 2px 2px 10px 2px rgba(184, 184, 179, 0.2);
  border-radius: 24px;
}

.profile-picture.icon.default {
  width: auto;
  height: 100%;
  line-height: 0;
  @extend .u-m-0;
  @extend .u-p-0;

  text-align: center;

  svg {
    @extend .u-pt-2;
    line-height: 0;
    width: 70%;
    height: auto;
  }
}


.person-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .pill {
    margin-bottom: 0;
  }

  h4, h6 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    margin: 0 0 0.5rem;
  }

  h4.person-summary-name {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;

    span.pronouns {
      font-size: 16px;
      font-weight: 400;
      @extend .u-ml-2;
      color: #707070;
    }
  }

  h6 {
    font-size: 16px;
    vertical-align: middle;

    &.person-summary-job-title, &.person-summary-program {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #707070;
    }

    &.person-summary-course-title, &.person-summary-cohort-title, &.person-summary-program-title {
      margin-bottom: 1rem;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;

      span.prefix {
        font-weight: 400;
      }
    }
  }
}

.candidate-program-enrolment-buttons {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  button.button {
    @extend .u-m-0;

    &:not(:last-child) {
      @extend .u-mb-2;
    }
  }
}

.skills-summary, .interests-summary-pills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.skills-summary {
  .pill {
    background-color: $deep-green;
    color: $white;
  }
}

.interests-summary {
  @extend .u-mb-4;
  display: flex;
  flex-direction: column;

  .pill {
    color: $black;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    border-radius: 0;
    margin-right: 3rem;
  }
}

.team-candidate-card {
  .team-candidate-head {
    text-align: center;
  }

  .team-candidate-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button.button {
      margin: 0;
      align-self: flex-end;
      width: fit-content;
    }
  }
}

.team-candidate-modal-content, .card.team-advisor-card {
  img.profile-picture, span.icon.profile-picture.default svg {
    @extend .u-mb-4;
  }

  .candidate-head, .advisor-head {
    .person-summary, .skills-summary {
      @extend .hidden-md-up;
    }
  }

  .candidate-body, .advisor-body {
    .person-summary, .skills-summary {
      @extend .hidden-md-down;
    }
  }
}

.card.team-advisor-card {
  @extend .u-p-4;
}

.picture-section {
  margin: 0 0 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .picture-actions {
    @extend .u-ml-4;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .button {
      @extend .u-mb-2;
      width: 100%;
    }
  }
}

.payment-section.summary-section {
  .summary-section-body {
    h5 {
      @extend .u-mb-4;
    }

    .summary-section-item {
      @extend .u-mb-4;

      label, span {
        font-size: 1.5rem;
      }
    }
  }
}
