@function columnWidth($cols) {
  @return calc(100% / $cols - (($cols - 1) * 1em) / $cols);
}

.search-sort-filter {
  box-sizing: border-box;
  font-size: theme('fontSize.16');
  isolation: isolate;
  position: relative;

  &__header {
    align-content: flex-end;
    display: flex;
    justify-content: space-between;

    svg {
      align-self: center;
      cursor: pointer;
      fill: $dusky-blue;
      margin-left: auto;
      min-width: 1em;
    }
  }

  &__body {
    background: $tealish-blue;
    border-radius: 0.25em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 8px;
    padding: 1.25em 1.75em;

    .search-sort-filter__input-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      button {
        margin-bottom: 0;
        width: min-content;
      }

      input {
        background-color: #ffffff;
        border: 1px solid transparent;
        height: 2.5em;
        margin-bottom: 0;
        padding: 0 0.563em;
        width: 100%;

        &:focus {
          border-color: #013a81;
        }
      }

      label {
        cursor: pointer;
        margin-bottom: 0.25em;
      }

      .search-sort-filter__select-wrapper {
        align-items: center;
        display: flex;
        position: relative;

        select {
          background-color: #ffffff;
          background-image: unset;
          border: 1px solid transparent;
          cursor: pointer;
          height: 2.5em;
          margin-bottom: 0;
          padding: 0 0.563em;
          width: 100%;

          &:focus {
            border-color: #013a81;
          }
        }

        svg {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          right: 0.563em;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .search-sort-filter .search-sort-filter__body .search-sort-filter__input-wrapper {
    width: columnWidth(2);
  }
}

@media screen and (min-width: 768px) {
  .search-sort-filter .search-sort-filter__body .search-sort-filter__input-wrapper {
    width: columnWidth(3);
  }
}

@media screen and (min-width: 992px) {
  .search-sort-filter .search-sort-filter__body .search-sort-filter__input-wrapper {
    width: columnWidth(4);
  }
}

@media screen and (min-width: 1200px) {
  .search-sort-filter .search-sort-filter__body .search-sort-filter__input-wrapper {
    width: columnWidth(5);
  }
}

@media screen and (min-width: 1400px) {
  .search-sort-filter .search-sort-filter__body .search-sort-filter__input-wrapper {
    width: columnWidth(6);
  }
}
