.datagrid-container {
  width: 100%;
  font-size: 1.6rem;
  color: $off-black;

  .datagrid-inner-container {

    width: auto;
    box-shadow: 2px 2px 10px 2px rgba(184, 184, 179, 0.2);
    border-radius: 24px;
    padding: 3rem 6rem;

    .datagrid-head {
      @extend .u-mb-4;
      @extend .u-pb-0;
      background: transparent;


      .datagrid-head-right {
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 0rem;
      }

      .datagrid-actions {
        button.button {
          margin-bottom: 0.75rem;
          margin-left: 0.75rem;
          height: 37px;
        }
      }

      .form-input-container {
        padding: 0;
        width: auto;
        min-width: 100px;

        label {
          font-size: 14px;
          margin: 0;
          padding: 0;
          color: $off-black;
        }

        input,
        select {
          min-width: unset;
          background-color: transparent;
        }

        .react-select {
          .react-select-placeholder {
            color: $off-black;
          }
        }
      }
    }

    table {
      border: 0;

      tbody:before {
        content: "@";
        display: block;
        line-height: 15px;
        text-indent: -99999px;
      }

      tr {
        border: 0;
        background: transparent;

        &.clickable {
          cursor: pointer;

          &:hover {
            background-color: #FAFAFA;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #F3F2F2;
        }
      }

      td, th {
        border: 0;
        padding: 1rem;
      }

      thead {
        background-color: transparent;
        border: 0;

        tr, tr:hover {
          margin-top: 1rem;
          margin-bottom: 1rem;

          th {
            margin-bottom: 4rem;
          }
        }
      }
    }

    .datagrid-view-link {
      padding: 0 1rem;
      color: $emerald;

      .icon, .icon svg {
        line-height: 0;
      }
    }
  }


  table {
    background: transparent;

    thead {
      border: none;
      background: transparent;

      tr {
        th {
          background-color: $dusky-blue;
          color: $white;
        }
      }
    }

    tbody {
      border: none;
      background: transparent;
      box-shadow: none;
      padding: 2rem;

      button {
        margin: 0;
      }
    }
  }

  thead tr:nth-child(2) {
    th {
      background-color: white;
      border-color: $dusky-blue;
    }
  }

  .pagination {
    margin-top: 2.5rem;

    .page-count, .page-number, .menu .menu-title {
      font-size: 1.5rem;
      color: $black;
    }

    .page-count {
      margin-top: 2px;
    }

    ul {
      display: table-row;

      li {
        display: table-cell;
        vertical-align: middle;

        .page {
          margin: 0;
          padding: 0;
        }

        &.page-number {
          margin-top: 4px;
          background-color: transparent;
          cursor: pointer;

          &.selected-page {
            color: $emerald;
            cursor: default;
          }
        }

        &.page-arrow.next, &.page-arrow.prev {
          padding: 5px;
          border-radius: 50%;
          background-color: $emerald;
          color: $white;
          line-height: 0;

          &.disabled {
            background-color: $alto;
          }
        }
      }
    }
  }

  &.datagrid-head-bottom {
    .datagrid-inner-container {
      display: flex;
      flex-direction: column-reverse;

      .datagrid-head {
        margin-top: 1rem;
        margin-bottom: 0;

        .datagrid-actions {
          button.button {
            margin-bottom: 0;
          }
        }
      }
    }
  }


  &.datagrid-minimal {
    .datagrid-inner-container {
      padding: 0;
      box-shadow: none;
      border-radius: 0;

      .datagrid-actions {
        flex: 1;
        button.button {
          margin-bottom: 0;
        }
      }
    }

    &.datagrid-minimal-rounded {
      .datagrid-inner-container {
        border-radius: 24px;
      }
    }

    table {
      thead {
        &::after {
          content: '';
          display: block;
          height: 0px;
          clear: both;
        }
      }

      tbody {
        &::before {
          content: '';
          display: block;
          height: 0px;
          clear: both;
        }

        td {
          padding-top: 0.5rem;
          padding-bottom: 0rem;
        }
      }
    }
  }
}

.datagrid-container.datagrid-stacked-head .datagrid-inner-container .datagrid-head .datagrid-head-right {
  flex-direction: column-reverse;
  gap: 1rem;
  button.button {
    margin-bottom: 0;
  }
}
