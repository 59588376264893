.button,
button:not(.switch-root):not([role='checkbox']):not([role='tab']):not(.unstyled-button):not(.tox-tbtn):not(.ReactTags__remove):not(.accordion-trigger) {
  border-radius: 40px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding: 0 22px;
  height: 34px;
  font-size: 1.25rem;
  line-height: 2;

  &.button-icon,
  &.button-small.button-icon {
    padding: 0px;
  }

  &.button-large {
    border-radius: 40px;
    font-size: 1.2rem;
  }

  &.button-primary {
    color: $sea-green;
    border: $emerald 1px solid;
    background-color: $emerald;

    &:hover.disabled,
    &:hover[disabled] {
      color: $sea-green;
      background-color: $emerald;
    }

    &:hover:not(.disabled):not([disabled]),
    &:focus:not(.disabled):not([disabled]) {
      color: $sea-green;
      background-color: darken($emerald, 10%);
    }
  }

  &.button-cancel {
    background-color: transparent;
    border: $emerald 1px solid;
    color: $sea-green;

    &.button-icon {
      border: 0;
    }

    &:hover {
      background-color: transparent !important;
      border-color: darken($emerald, 20%);
      color: $sea-green !important;
    }

    &:hover.disabled,
    &:hover[disabled] {
      background-color: transparent !important;
      border-color: $emerald;
      color: $sea-green !important;
    }

    &:hover:not(.disabled):not([disabled]),
    &:focus:not(.disabled):not([disabled]) {
      background-color: transparent !important;
      border-color: darken($emerald, 20%);
      color: $sea-green !important;
    }
  }

  &.button-remove {
    background-color: transparent;
    border: $valencia 1px solid;
    color: $valencia;

    &:hover {
      background-color: transparent !important;
      border-color: darken($valencia, 20%);
      color: $valencia !important;
    }
  }

  &.button-edit {
    border: 0;
    color: #0d4608;

    .icon,
    .icon svg {
      line-height: 0;
    }
  }

  &.button-back {
    width: 5rem;
    height: 5rem;
    margin: 0;
    padding: 0 2px 0 0;
    border: 0;
    background: #ffffff;
    box-shadow: 0px 4px 20px #e9e4da;
    color: #013906;

    .icon {
      margin: 5px;
    }

    .icon,
    .icon svg {
      line-height: 0;
      width: 2rem;
      height: 2rem;
    }
  }

  &.button-icon.button-social-icon {
    height: auto;
    background-color: transparent;
    color: $boulder;
    border: 0;
    padding: 0;
    margin: 0 0.5rem;
    font-size: 6rem;
    line-height: 8rem;

    &:focus,
    &:hover {
      color: $emerald;
    }
  }
}
