@tailwind base;
@tailwind components;
@tailwind utilities;

.inspring-app {
  @import "components/onboarding/onboarding";
  @import "./components/enrolment";
  @import "./components/shared/accordion";
  @import "./components/shared/address";
  @import "./components/shared/buttons";
  @import "./components/shared/card";
  @import "./components/shared/icon";
  @import "./components/shared/pill";
  @import "./components/shared/datagrid";
  @import "./components/shared/forms";
  @import "./components/shared/modals";
  @import "./components/shared/profiles";
  @import "./components/shared/upload";
  @import "./components/shared/subnav";
  @import "./components/shared/tabs";
  @import "./components/advisor-portal";
  @import "./components/candidate-portal";
  @import "./components/admin";

  .app-wrapper {
    //height: 100vh;
    //min-height: 100vh;
    font-family: "Roboto", sans-serif;
    width: 100%;
    background-color: $bg-color;

    header.aliro-header {
      align-items: center;
      background: $white;
      box-shadow: 0px 4px 20px 10px #E9E4DA;
      height: $menu-height;
      max-height: $menu-height;
      display: table-cell;
      z-index: 1;

      @include on-mobile {
        height: $menu-height-mobile;
        max-height: $menu-height-mobile;
      }

      .aliro-header-wrapper {
        width: 100%;
        height: $menu-height;
        max-height: $menu-height;
        padding: 0;
        display: flex;

        > div {
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-self: center;
        }

        @include on-mobile {
          height: $menu-height-mobile;
          max-height: $menu-height-mobile;

          > div {
            padding: 0;
          }
        }
      }

      .logo-container {
        margin-top: 12px;
        margin-bottom: 10px;

        img.home-logo {
          height: 100%;
        }
      }

      .icon, .icon svg {
        line-height: 0;
        margin: 0;
        padding: 0;
      }

      .menu-title {
        height: 35px;
      }
    }

    .content-wrapper {
      margin-top: $menu-height;
      min-height: calc(100vh - #{$menu-height});
      //max-height: calc(100vh - #{$menu-height});

      @include on-mobile {
        height: calc(100vh - #{$menu-height-mobile});
        max-height: calc(100vh - #{$menu-height-mobile});
        margin-top: $menu-height-mobile;
      }

      div.main-content.short {
        height: inherit;
        margin-top: 0px;
        padding-top: 2rem;
        padding-bottom: 2rem;

        &.no-padding {
          padding-top: 0;
          padding-bottom: 0;
          width: 100%;
        }
      }
    }
  }

  h1,
  h2 {
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }

  h3,
  h4,
  h5 {
    font-family: 'Roboto', sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    margin: 0;
  }

  h1 {
    font-size: 3.6rem;
  }

  h2 {
    color: $tundora;
    font-size: 3rem;
    font-weight: $fw-light;
  }

  h3 {
    font-size: 2.2rem;
    font-weight: $fw-regular;
  }

  h4 {
    font-size: 2rem;
    font-weight: $fw-bold;
  }

  h5 {
    font-size: 1.8rem;
    font-weight: $fw-regular;
  }

  h1, h2 {
    font-family: "Merriweather", serif;
  }

  a {
    text-decoration: none;
  }
}
