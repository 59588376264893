.footer {
  @include for-size(phone-only) {
    .copyright,
    .terms-privacy {
      display: block;
    }
  }
  background: $tundora;
  bottom: 0;
  box-sizing: border-box;
  color: $white;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: theme('fontSize.12');
  padding: 12px 0;
  text-align: center;
  width: 100%;

  a {
    color: $white;
  }

  .terms-privacy {
    font-weight: $fw-bold;
    margin-left: 15px;
    margin-top: 0;

    a {
      margin-right: 8px;
      text-decoration: none;
    }
  }
}
