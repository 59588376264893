.slideIn {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-name: slideDownAndFade; // Will need changed if we add more dropdown orientations
}

.dropdown-item {
  padding: 0.2rem 1.6rem;
  cursor: pointer;
  background: transparent;
  transition: 0.2s background ease-out;

  &[data-highlighted] {
    background: rgba(0, 0, 0, 0.05);
  }

  a,
  a:hover {
    text-decoration: none;
  }

  &:first-of-type {
    // The true first-child will likely be the dropdown arrow.
    padding-top: 0.8rem;
  }
  &:last-child {
    padding-bottom: 0.8rem;
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
