.menu {
  position: relative;
  > .menu-title,
  li {
    box-sizing: border-box;
    font-size: theme('fontSize.14');
    height: 40px;
    line-height: 2.8;
    padding: 0 16px;
  }

  > .menu-title {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    &.active {
      background: $acqua-squeeze;
      border-radius: 4px 4px 0 0;
      box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px;
      color: $tundora;
      ol,
      ul {
        display: block;
        left: 0;
        position: absolute;
        top: 100%;
        z-index: 50;
      }
    }

    ol,
    ul {
      background: $acqua-squeeze;
      border-radius: 0 0 4px 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px;
      display: none;
      list-style: none;
      margin: 0;
      width: 100%;
    }

    li.divider {
      border-top: 1px solid $silver;
    }

    li {
      cursor: pointer;
      margin: 0;
      white-space: nowrap;
      &:hover {
        background: $black-squeeze;
      }

      &:last-child:hover {
        border-radius: 0 0 4px 4px;
      }

      a {
        color: $tundora;
        display: block;
        text-decoration: none;
        &.log-out,
        &.delete {
          color: $valencia;
          font-family: 'Karla', sans-serif;
        }
      }
    }
  }

  &.chevron {
    > .menu-title {
      display: inline-block;
      &::after {
        background: transparent url('https://images.onaliro.com/client/chevron_down_blue.svg') no-repeat scroll;
        background-size: 8px;
        content: ' ';
        display: inline-block;
        height: 6px;
        margin-left: 8px;
        transition: transform 0.25s;
        vertical-align: middle;
        width: 8px;
      }

      &.active {
        background: $acqua-squeeze;
        border-radius: 4px 4px 0 0;
        box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px;
        color: $tundora;
        &::after {
          transform: rotateZ(180deg);
          transform-origin: 50% 40% 0;
        }
      }
    }
  }

  &.select {
    li {
      &::before {
        background: $white;
        border-radius: 50%;
        content: ' ';
        float: left;
        height: 20px;
        margin-right: 11px;
        margin-top: 9px;
        width: 20px;
      }

      &.selected {
        &::before {
          background: $white url('https://images.onaliro.com/client/icon_check_green.png') no-repeat scroll center;
          background-size: 14px;
        }
      }
    }
  }

  &.alt {
    > .menu-title {
      &.active {
        background: $deep-cerulean;
        color: $white;
        &::after {
          background-image: url('https://images.onaliro.com/client/chevron_down_white.png');
        }
      }

      ul {
        background: $deep-cerulean;
      }
    }

    &.select {
      li.selected::before {
        background-image: url('https://images.onaliro.com/client/icon_check_blue.png');
      }
    }
  }
}
