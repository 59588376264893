.text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: $off-black;
  font-style: normal;
  font-size: theme('fontSize.16');
  line-height: 2rem;
  letter-spacing: 0.25px;
}

.text.light {
  font-weight: 300;
}

.text.medium {
  font-weight: 500;
}

.text.semibold {
  font-weight: 600;
}

.text.bold {
  font-weight: 700;
}

.text.small {
  font-size: theme('fontSize.14');
  line-height: 2rem;
}

.text.large {
  font-size: theme('fontSize.18');
  line-height: 2.2rem;
}

.text.larger {
  font-size: theme('fontSize.20');
  line-height: 2.4rem;
}

  .text.x-large {
  font-size: theme('fontSize.24');
  line-height: 2.8rem;
}

.text.xx-large {
  font-size: theme('fontSize.32');
  line-height: 3.6rem;
}

.text.white {
  color: $white;
}

.text.inherit {
  color: inherit;
}

.text.gray {
  color: $smoke;
}

.text.link {
  color: $active-link-text;
  text-decoration-line: underline;
}

.text.wrap {
  white-space: normal;
}

.text.nowrap {
  white-space: nowrap;
}

.text.wrap-any-break {
  text-wrap: normal;
  white-space: normal;
  word-break: break-word;
}

.text.clip {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-pill {
  border-radius: 2px;
  padding: 0.4rem 0.8rem;
  @include for-size(tablet-landscape-up) {
    padding: 0.8rem 1.2rem;
  }

  &.tight {
    padding: 0.2rem 0.4rem;
    @include for-size(tablet-landscape-up) {
      padding: 0.4rem 0.8rem;
    }
  }
}

.text.capitalize {
  text-transform: capitalize;
}

.text.uppercase {
  text-transform: uppercase;
}

.text.lowercase {
  text-transform: lowercase;
}

.pointer {
  cursor: pointer;
}
