div.modal {
  border-radius: 25px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px 10px rgba(0, 0, 0, 0.1);

  > div:first-child {
    max-height: 100%;
  }

  @include for-size(phone-only) {
    padding-top: 3rem;
  }

  h1, h2, h3, h4, h5, h6 {
    @extend .u-mb-4;
    text-align: left;
  }

  button, button.button, form {
    margin-bottom: 0;
  }

  &.compact-form {
    .form-input-container {
      margin-bottom: 0.5rem;
    }
    input.form-input, textarea.form-input, select.form-input {
      height: auto;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}
