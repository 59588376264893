/* We're stacking the class name selector to increase the calculated specificity so we can easily override the existing, and unwanted, styles */
.styled-color-input.styled-color-input.styled-color-input.styled-color-input.styled-color-input.styled-color-input.styled-color-input.styled-color-input.styled-color-input.styled-color-input {
  input[type="color"] {
    all: unset;
    background-color: $dark-white;
    border-radius: 4px;
    box-sizing: border-box;
    height: auto;
    margin: 0;
    padding: 0;
    width: 50px;
  }
}