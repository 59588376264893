.tabs {
  margin-top: 1.5rem;

  .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1rem;
    margin-bottom: 0.5rem;

    button[role="tab"].link {
      font-size: 2rem;
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
      color: $off-black;
      text-decoration: none;
      background: none;
      border: 2px solid transparent;
      border-radius: 0;
      padding: 0 0 1rem;

      &[data-state="active"] {
        border-bottom: 2px solid $dusky-blue;
        border-radius: 0;
        font-weight: 700;
        color: $off-black;

        &:hover {
          color: $off-black;
        }
      }

      &:hover {
        border-bottom: 2px solid $dusky-blue;
        border-radius: 0;
        color: $dusky-blue;
      }

    }
  }
}
