.arrow-right {
  background: transparent url('https://images.onaliro.com/client/icon_chevron_forward.png') no-repeat scroll 0 0;
  background-size: 27px;
}

.icon-expand {
  background: transparent url('https://images.onaliro.com/client/icon_expand.png') no-repeat scroll 0 0;
  background-size: 23px;
}

.icon-search {
  background: transparent url('https://images.onaliro.com/client/icon_search.svg') no-repeat scroll 0 center;
  background-size: 18px;
}

.icon-check {
  background: transparent url('https://images.onaliro.com/client/icon_check_green.png') no-repeat scroll left center;
  background-size: 14px;
}

.icon-salary {
  background: transparent url('https://images.onaliro.com/client/icon_success_fee.svg') no-repeat scroll 0 0;
  background-size: 14px;
}

.icon-location {
  background: transparent url('https://images.onaliro.com/client/icon_location.png') no-repeat scroll 0 0;
  background-size: 14px;
}

.icon-veteran {
  background: transparent url('https://images.onaliro.com/client/alirovets/vetpreferred.png') no-repeat scroll 0 0;
  background-size: 20px;
}

.unreviewed-ico {
  background: url('https://images.onaliro.com/client/ic_candidates_unreviewed.png') no-repeat center / 12px;
}

.favorited-ico {
  background: url('https://images.onaliro.com/client/ic_candidates_favorites.png') no-repeat center / 12px;
}

.ignored-ico {
  background: url('https://images.onaliro.com/client/ic_candidates_ignored.png') no-repeat center / 12px;
}

.closed-ico {
  background: url('https://images.onaliro.com/client/ic_candidates_closed.png') no-repeat center / 12px;
}

.hired-ico {
  background: url('https://images.onaliro.com/client/icon_profile.png') no-repeat center / 12px;
}

.selected-candidate h3.closed {
  background: url('https://images.onaliro.com/client/ic_candidates_closed.png') no-repeat;
  background-position: 0 18px;
  background-size: 22px;
}

.interviewing-ico {
  background: url('https://images.onaliro.com/client/ic_candidates_interviewing.png') no-repeat center / 20px;
}

.selected-candidate h3.interviewing {
  background-position: 0 2px;
  background-size: 38px;
}

.candidate-list tr td div.interviewing {
  background-position: 6px 0;
}

.candidate-totals li.interviewing {
  background-position: 1px 0;
}

.alirovet-candidate-ico {
  background: url('https://images.onaliro.com/client/alirovets/vetpreferred.png');
  background-repeat: no-repeat;
  background-size: 15px 15px;
  padding-left: 20px;
}

.icon {
  @extend .u-m-0;
  @extend .u-p-0;

  vertical-align: middle;
  line-height: normal;

  svg {
    @extend .u-m-0;
    @extend .u-p-0;
  }

  &.icon-xs {
    font-size: 0.5rem;
  }

  &.icon-sm {
    font-size: 1rem;
  }

  &.icon-md {
    font-size: 1.5rem;
  }

  &.icon-lg {
    font-size: theme('fontSize.20');
  }

  &.icon-xl {
    font-size: theme('fontSize.40');
  }

  &.icon-primary {
    color: $emerald;
  }

  &.icon-gray {
    color: $regent-gray;
  }

  &.icon-light {
    color: $black-squeeze;
  }

  &.icon-dark {
    color: $deep-green;
  }

  &.icon-remove {
    color: $valencia;
  }

  &.icon-orange {
    color: $orange;
  }

  &.icon-blue {
    color: $deep-cerulean;
  }

  &.icon-purple {
    color: $purple;
  }
}
