.modal {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100;

  padding: 25px;
  width: 420px;
  max-width: 95vw;
  max-height: 85vh;
  overflow-y: auto;

  > div:first-child {
    max-height: 84vh;

    // Hide scrollbar
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  @include on-mobile {
    padding: 15px;
    max-width: 85vw;
  }

  &.modal--small {
    padding: 15px;
    width: 300px;
  }

  &.modal--medium {
    padding: 35px;
    width: 600px;

    @include on-mobile {
      padding: 20px;
    }
  }

  &.modal--large {
    width: 900px;
  }

  h2 {
    margin: 20px 0;
    text-align: center;
  }

  .sections {
    margin: 0 30px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: theme('fontSize.24');
    color: $black;
    cursor: pointer;
  }

  .switch-link {
    background: transparent url('https://images.onaliro.com/client/icon_profile.png') no-repeat scroll left center;
    background-size: 10px 12px;
    float: right;
    font-family: 'Roboto Slab', serif;
    font-weight: $fw-regular;
    line-height: 2.25;
    padding-left: 18px;
  }

  form {
    margin-bottom: 20px;
  }

  fieldset {
    width: 100%;
    a {
      display: inline-block;
      margin-top: 10px;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='date'],
  select,
  textarea {
    &:not(.form-input) {
      font-size: theme('fontSize.14');
      height: 34px;
      margin-bottom: 6px;
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  textarea {
    height: auto;
  }

  .password-container img {
    top: 11px;
  }

  .veteran-wrapper {
    display: flex;
    flex-direction: row;
    .veteranOption {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 12px;
      vertical-align: middle;
    }
  }

  .button,
  button,
  input[type='submit'],
  input[type='reset'],
  input[type='button'] {
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.3;
      &:hover {
        background-color: $emerald;
        border-color: $emerald;
        color: $white;
      }
    }
  }

  .error-messages {
    color: $valencia;
    font-size: theme('fontSize.14');
    margin: 0 0 6px;
    padding-left: 14px;
  }

  @media screen and (max-width: 767px) {
    &.modal--app-card {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-sizing: border-box;
      height: 100%;
      margin-top: 48px;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
