@use 'sass:math';

$colors: (
  'primary': $emerald,
  'white': $white,
  'blue': $deep-cerulean,
  'purple': $purple,
  'cancel': $regent-gray,
  'draft': $regent-gray,
  'complete': $black-squeeze,
  'light-grey': $black-squeeze,
  'remove': $valencia,
  'black': $black,
  'green': $deep-green,
  'default': $bg-color,
  'dark-default': $gallery,
  'green-cyan': $green-cyan,
  'pale-green': $pale-green,
);
$displays: block, inline-block, flex, inline-flex, none;
$flexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
$flex-directions: row, column, row-reverse, column-reverse;
$flex-wraps: wrap, nowrap;
$flex-justifications: flex-start, flex-end, center, space-between, space-around, space-evenly;
$flex-alignments: stretch, flex-start, flex-end, center, baseline;
$text-alignments: left, right, center, justify;
$vertical-alignments: top, middle, bottom;

@for $i from 0 through 10 {
  $gapRem: calc(($i * 2) / 10);
  .u-gap-#{$i} {
    gap: #{$gapRem}rem;
  }
}

@for $i from 0 through 20 {
  $width: $i * 5;

  .u-w-#{$width} {
    width: math.percentage($width);
  }

  .u-h-#{$width} {
    height: math.percentage($width);
  }
}

@for $i from 1 through length($displays) {
  $display: nth($displays, $i);

  .u-display-#{$display} {
    display: $display;
  }
}

@for $i from 1 through length($flexes) {
  $flex: nth($flexes, $i);

  .u-flex-#{$flex} {
    flex: $flex;
  }
}

@for $i from 1 through length($flex-directions) {
  $flex-direction: nth($flex-directions, $i);

  .u-flex-direction-#{$flex-direction} {
    flex-direction: $flex-direction;
  }
}

@for $i from 1 through length($flex-wraps) {
  $flex-wrap: nth($flex-wraps, $i);

  .u-flex-wrap-#{$flex-wrap} {
    flex-wrap: $flex-wrap;
  }
}

@for $i from 1 through length($flex-justifications) {
  $flex-justification: nth($flex-justifications, $i);

  .u-justify-items-#{$flex-justification} {
    justify-items: $flex-justification;
  }
  .u-justify-content-#{$flex-justification} {
    justify-content: $flex-justification;
  }
  .u-justify-self-#{$flex-justification} {
    justify-self: $flex-justification;
  }
}

@for $i from 1 through length($flex-alignments) {
  $flex-alignment: nth($flex-alignments, $i);

  .u-align-items-#{$flex-alignment} {
    align-items: $flex-alignment;
  }
  .u-align-self-#{$flex-alignment} {
    align-self: $flex-alignment;
  }
  .u-align-content-#{$flex-alignment} {
    align-content: $flex-alignment;
  }
}

@each $color, $value in $colors {
  .u-bg-#{$color} {
    background-color: $value !important;
  }
  .u-color-#{$color} {
    color: $value !important;
  }
  .u-border-color-#{$color} {
    border-color: $value !important;
  }
}

@for $i from 1 through length($text-alignments) {
  $text-alignment: nth($text-alignments, $i);

  .u-text-align-#{$text-alignment} {
    text-align: $text-alignment;
  }
}

@for $i from 1 through length($vertical-alignments) {
  $vertical-alignment: nth($vertical-alignments, $i);

  .u-vertical-align-#{$vertical-alignment} {
    vertical-align: $vertical-alignment;
  }
}
