.pipeline {
  align-items: stretch;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  font-size: theme('fontSize.14');
  overflow: hidden;

  .pipeline__header {
    background-color: $dusky-blue;
    border-radius: 0.25em;
    color: $white;
    font-weight: 700;
    overflow: hidden;
    padding: 0.25em 1.25em;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      font-weight: 400;
    }
  }

  .pipeline__body {
    border-radius: 0.25em;
    flex: 1;
    margin-top: 0.625em;

    &.pipeline__body--active {
      background-color: $smoke;
      opacity: 0.5;
    }

    .pipeline__card {
      isolation: isolate;
      margin-bottom: 1em;
      position: relative;

      .pipeline__card-content {
        background-color: $white;
        border-left: solid 0.375em $white;
        border-radius: 0.25em;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        color: $dark-text;
        cursor: pointer;
        line-height: 1.25em;
        padding: 1.25em 1.125em;

        &.pipeline__card-content--gray {
          border-left-color: #d9d9d9;
        }

        &.pipeline__card-content--green {
          border-left-color: #c8ffb5;
        }

        &.pipeline__card-content--orange {
          border-left-color: #ffd4b5;
        }

        &.pipeline__card-content--pink {
          border-left-color: #ffb5d9;
        }

        &.pipeline__card-content--yellow {
          border-left-color: #ffeab5;
        }

        span {
          color: $smoke;
        }

        .pipeline__card-remove {
          fill: $smoke;
          position: absolute;
          right: 0.75em;
          top: 0.75em;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
