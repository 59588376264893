@mixin generate($prefix, $property) {
  // List of sizes to generate for each
  $sizes: [0, 0.2, 0.4, 0.8, 1.2, 1.6];
  // Spacing to multiply the sizes by
  $spacing: 1rem;

  // Loop through all of the sizes(we use @for rather than @each, as we want access to the index)
  @for $i from 1 through length($sizes) {
    // Get the size for the current index
    $size: nth($sizes, $i);

    // Create the rule
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size;
    }
  }
}

@include generate(u-border-radius, border-radius);

.u-border-1 {
  border: 1px solid $boulder;
}

.u-border-2 {
  border: 2px solid $boulder;
}

/**
*
* Shadows
*
 */
$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

.u-shadow-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0 3px 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0 1px 5px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}

.u-shadow-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0 1px 10px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity), 0 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}

.u-shadow-3 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
    0 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}

.u-shadow-4 {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0 11px 15px -7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
    0 24px 38px 3px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}
